<template>
    <a :href="href" class="bg-slate-50 text-slate-500 inline-flex hover:bg-slate-200 hover:text-slate-800 items-center justify-center rounded
        size-[30px] md:size-[50px]">
        <component :is="iconComponent" class="size-[14px] md:size-[20px]" aria-hidden="true" />
    </a>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { PencilIcon } from '@heroicons/vue/outline';

const props = defineProps({
    href: String,
    icon: Object
});

const iconComponent = computed(() => props.icon || PencilIcon);
</script>