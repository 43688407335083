<template>
  <app-layout :title="title">
    <template #header>
      <div class="flex w-full justify-between items-center">
        <h2 class="flex flex-col md:flex-row md:items-center md:text-[24px] text-[14px] leading-none" v-html="title"></h2>
        <div class="flex gap-x-2 md:gap-x-10">
          <app-useful-links :items="usefulLinks" />
          <button
            class="h-[42px] px-4 rounded-md text-red-500 border border-red-500 hover:bg-red-100 transition"
            @click="confirmDeletion(model.id)"
          >
            Remove
          </button>
        </div>
      </div>
    </template>

    <div class="mx-auto py-4">

      <div class="w-full">
        <nav class="border-b text-sm flex justify-start">
          <a
            :class="{
              'inline-block py-2 mr-4 text-slate-600 hover:text-slate-900': true,
              'border-b-2 border-orange-600': tab === 1,
            }"
            href="#"
            @click.prevent="currentTab(1)"
          >
            Data
          </a>
          <a
            :class="{
              'inline-block py-2 mr-4 text-slate-600 hover:text-slate-900': true,
              'border-b-2 border-orange-600': tab === 2,
            }"
            href="#"
            @click.prevent="currentTab(2)"
          >
            System
          </a>
        </nav>

        <div class="pt-10">
          <div v-if="tab === 1">
            <form @submit.prevent="submit">
              <div class="grid grid-cols-6 gap-6">
                
                <!-- 🔍 Поисковый Запрос -->
                <div class="col-span-6">
                  <jet-label for="query" value="🔍 Поисковый запрос (до 400 символов)" />
                  <jet-input id="query" type="text" class="block w-full mt-1" v-model="form.query" maxlength="400" autofocus />
                  <jet-input-error :message="form.errors.query" class="mt-2" />
                </div>

                <!-- 📌 Тип Поиска -->
                <div class="col-span-6 sm:col-span-3">
                  <jet-label for="search_type" value="📌 Тип поиска" />
                  <select id="search_type" v-model="form.search_type" class="block w-full mt-1 border rounded p-2">
                    <option value="SEARCH_TYPE_RU">Русский</option>
                    <option value="SEARCH_TYPE_TR">Турецкий</option>
                    <option value="SEARCH_TYPE_COM">Международный</option>
                  </select>
                </div>

                <!-- 🚫 Фильтрация контента -->
                <div class="col-span-6 sm:col-span-3">
                  <jet-label for="family_mode" value="🚫 Фильтр контента" />
                  <select id="family_mode" v-model="form.family_mode" class="block w-full mt-1 border rounded p-2">
                    <option value="FAMILY_MODE_NONE">Выключен</option>
                    <option value="FAMILY_MODE_MODERATE">Умеренный (по умолчанию)</option>
                    <option value="FAMILY_MODE_STRICT">Строгий</option>
                  </select>
                </div>

                <!-- ⏳ Сортировка -->
                <div class="col-span-6 sm:col-span-3">
                  <jet-label for="sort_mode" value="⏳ Режим сортировки" />
                  <select id="sort_mode" v-model="form.sort_mode" class="block w-full mt-1 border rounded p-2">
                    <option value="SORT_MODE_BY_RELEVANCE">По релевантности (по умолчанию)</option>
                    <option value="SORT_MODE_BY_TIME">По времени</option>
                  </select>
                </div>

                <!-- ↕️ Порядок сортировки -->
                <div class="col-span-6 sm:col-span-3">
                  <jet-label for="sort_order" value="↕️ Порядок сортировки" />
                  <select id="sort_order" v-model="form.sort_order" class="block w-full mt-1 border rounded p-2">
                    <option value="SORT_ORDER_DESC">Сначала новые</option>
                    <option value="SORT_ORDER_ASC">Сначала старые</option>
                  </select>
                </div>

                <!-- 📦 Группировка -->
                <div class="col-span-6 sm:col-span-3">
                  <jet-label for="group_mode" value="📦 Группировка" />
                  <select id="group_mode" v-model="form.group_mode" class="block w-full mt-1 border rounded p-2">
                    <option value="GROUP_MODE_DEEP">По доменам</option>
                    <option value="GROUP_MODE_FLAT">Один документ в группе</option>
                  </select>
                </div>

                <!-- 🔢 Количество групп на странице -->
                <div class="col-span-6 sm:col-span-2">
                  <jet-label for="groups_on_page" value="🔢 Групп на странице (1-100)" />
                  <jet-input id="groups_on_page" type="number" class="block w-full mt-1" v-model="form.groups_on_page" min="1" max="100" />
                </div>

                <!-- 📄 Документы в группе -->
                <div class="col-span-6 sm:col-span-2">
                  <jet-label for="docs_in_group" value="📄 Документов в группе (1-3)" />
                  <jet-input id="docs_in_group" type="number" class="block w-full mt-1" v-model="form.docs_in_group" min="1" max="3" />
                </div>

                <!-- 🔎 Максимальное число пассажей -->
                <div class="col-span-6 sm:col-span-2">
                  <jet-label for="max_passages" value="🔎 Макс. пассажей (1-5)" />
                  <jet-input id="max_passages" type="number" class="block w-full mt-1" v-model="form.max_passages" min="1" max="5" />
                </div>

                <!-- 🌍 Регион -->
                <div class="col-span-6 sm:col-span-3">
                  <jet-label for="region" value="🌍 Регион (ID)" />
                  <jet-input id="region" type="number" class="block w-full mt-1" v-model="form.region" />
                </div>

                <!-- 🌐 Язык -->
                <div class="col-span-6 sm:col-span-3">
                  <jet-label for="l10n" value="🌐 Язык уведомлений" />
                  <jet-input id="l10n" type="text" class="block w-full mt-1" v-model="form.l10n" />
                </div>

                <!-- ✅ Опубликовано -->
                <div class="col-span-6">
                  <jet-label for="published" value="✅ Опубликовано" />
                  <label class="flex items-center mt-2">
                    <jet-checkbox v-model:checked="form.published" />
                    <span class="ml-2 text-sm text-slate-600">Да</span>
                  </label>
                </div>
              </div>

              <div class="flex items-center justify-start pt-8">
                <jet-button :disabled="form.processing">Сохранить</jet-button>
                <jet-action-message :on="form.recentlySuccessful" class="ml-3">Сохранено.</jet-action-message>
              </div>
            </form>
          </div>
          <div v-if="tab === 2">
            <pre class="bg-red-500 text-white size-[700px]">{{ props }}</pre>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>


<script setup>
import { ref } from 'vue';
import { useForm } from '@inertiajs/inertia-vue3';
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetActionMessage from '@/Jetstream/ActionMessage.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import AppUsefulLinks from '@/Components/usefulLinks.vue';

const props = defineProps({
  model: Object,
  prefixRoute: String,
  title: String,
  usefulLinks: Object,
});

const tab = ref(1)
const currentTab = (tabNumber) => (tab.value = tabNumber);

const beingDeleted = ref(null);
const confirmDeletion = (id) => {
  beingDeleted.value = id;
};

const form = useForm({ ...props.model });

const submit = () => {
  form.put(route(`${props.prefixRoute}.update`, props.model.id));
};

const deleteQuery = () => {
  form.delete(route(`${props.prefixRoute}.destroy`, props.model.id), {
    onSuccess: () => (beingDeleted.value = null),
  });
};
</script>