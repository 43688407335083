<template>
  <app-layout :title="``">
    <template #header>
      <div class="flex w-full justify-between items-center">
        <h2 class="flex flex-col md:flex-row md:items-center md:text-[24px] text-[14px] leading-none" v-html="title">
        </h2>
        <div class="flex gap-x-[5px] md:gap-x-[40px] justify-between">
          <app-useful-links :items="usefulLinks" />
          <a target="_self"
            class="h-[42px] cursor-pointer disabled:opacity-25 focus:border-red-700 focus:outline-none focus:ring focus:ring-red-200 font-light hover:bg-red-100 hover:border-red-600 inline-flex items-center justify-center px-4 rounded-md text-base text-red-500 tracking-widest transition"
            @click="confirmWorkDeletion(model.id)">Remove</a>
          <jet-button v-if="form.published" custom-class="ml-2 hidden md:flex" type="link" target="_blank"
            :href="`https://shuka.design/work/${model.url}`">
            Show
          </jet-button>
        </div>
      </div>
    </template>

    <div class="mx-auto py-4">
      <div class="w-full">
        <nav class="border-b text-sm flex justify-start">
          <app-tabs-label title="Blocks" :is-active="tab === 1" :is-icon="true"
            :icon-name="model.is_valid_blocks ? 'success' : 'warning'" @action="currentTab(1)" />

          <app-tabs-label title="Settings" :is-active="tab === 2" :is-icon="true"
            :icon-name="model.is_valid_media ? 'success' : 'warning'" @action="currentTab(2)" />

          <app-tabs-label title="Teams" :is-active="tab === 3" :is-icon="true"
            :icon-name="model.is_valid_teams ? 'success' : 'warning'" @action="currentTab(3)" />

          <app-tabs-label title="SEO & Share" :is-active="tab === 4" :is-icon="true"
            :icon-name="model.is_valid_seo ? 'success' : 'warning'" @action="currentTab(4)" />

          <app-tabs-label title="Activity" :is-active="tab === 5" :is-icon="true"
            :icon-name="model.is_valid_seo ? 'success' : 'warning'" @action="currentTab(5)" />
        </nav>
        <div class="pt-10">
          <div v-if="tab === 1">
            <div class="grid grid-cols-12 gap-y-[20px]">
              <div class="col-span-12 md:col-span-9">
                <draggable :list="list" item-key="order" class="list-group" @start="saveOriginalOrder" @end="checkMove"
                  v-bind="dragOptions">
                  <template #item="{ element, index }">
                    <div class="border border-slate-300 border-solid cursor-move list-group-item overflow-hidden
                     mb-[10px] p-[10px] rounded-[6px]
                     md:mb-4 md:p-5 md:rounded-2xl" :class="{
                      'not-draggable': element.lock_order,
                      'bg-red-100 translate-x-6 transition-transform':
                        element.id === workItemBeingDeleted,
                    }">
                      <div class="flex justify-between">
                        <div class="flex mb-4">
                          <a class="flex items-center justify-center bg-slate-600 hover:bg-red-600 leading-[20px] rounded-[4px] shrink-0 size-[30px] text-[20px] text-center text-slate-100"
                            :href="`/admin/work-items/${element.id}`">
                            {{ index + 1 }}
                          </a>
                          <span @input="(event) => {
                            editNameBlock(
                              event.target.textContent,
                              element.id
                            );
                          }
                            "
                            class="text-[16px] md:text-xl px-2 rounded-sm cursor-text min-w-[30px] outline-0 hover:ring-2 hover:ring-sky-300 hover:ring-offset-4 hover:ring-offset-sky-50 hover:bg-sky-50 focus:ring-2 focus:ring-sky-500 focus:ring-offset-4 focus:ring-offset-sky-100 focus:bg-sky-100"
                            contenteditable="true">{{ element.name }}</span>
                        </div>

                        <div class="flex gap-[10px] md:gap-[20px]">
                          <a class="text-red-500 hover:text-red-800 cursor-pointer"
                            @click="confirmWorkItemDeletion(element.id)">
                            Remove
                          </a>
                          <a class="text-slate-500 hover:text-slate-800 cursor-pointer"
                            :href="`/admin/work-items/${element.id}/duplicate`">
                            Duplicate
                          </a>
                          <a class="hidden md:inline-flex text-slate-500 hover:text-slate-800 cursor-pointer"
                            :href="`/admin/work-items/${element.id}/create-preset`">
                            Create preset
                          </a>
                        </div>
                      </div>

                      <div class="flex flex-row gap-[10px] md:gap-[20px]">
                        <div v-if="element.comp_l" class="bg-slate-100 px-4 py-2 rounded-lg">
                          <div class="text-base semibold opacity-30">L</div>
                          <div class="text-xs whitespace-nowrap">
                            {{ element.comp_l_name }}
                          </div>
                        </div>
                        <div v-if="element.comp_m" class="bg-slate-100 px-4 py-2 rounded-lg">
                          <div class="text-base semibold opacity-30">M</div>
                          <div class="text-xs whitespace-nowrap">
                            {{ element.comp_m_name }}
                          </div>
                        </div>
                        <div v-if="element.comp_s" class="bg-slate-100 px-4 py-2 rounded-lg">
                          <div class="text-base semibold opacity-30">S</div>
                          <div class="text-xs whitespace-nowrap">
                            {{ element.comp_s_name }}
                          </div>
                        </div>
                        <div v-if="element.comp_xs" class="bg-slate-100 px-4 py-2 rounded-lg">
                          <div class="text-base semibold opacity-30">XS</div>
                          <div class="text-xs whitespace-nowrap">
                            {{ element.comp_xs_name }}
                          </div>
                        </div>

                        <div class="bg-orange-100 rounded-lg grid grid-cols-2 overflow-hidden">
                          <div class="bg-sky-50 px-4 py-2">
                            <div class="grid grid-rols-2 gap-2 text-sky-800 opacity-50">
                              <div class="text-xs whitespace-nowrap">
                                {{ element.p_top }}
                              </div>
                              <div class="text-xs whitespace-nowrap">
                                {{ element.p_bottom }}
                              </div>
                            </div>
                          </div>

                          <div class="bg-red-50 px-4 py-2">
                            <div class="grid grid-rols-2 gap-2 text-red-800 opacity-50">
                              <div class="text-xs whitespace-nowrap">
                                {{ element.m_top }}
                              </div>
                              <div class="text-xs whitespace-nowrap">
                                {{ element.m_bottom }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </draggable>
                <div class="w-full mt-4">
                  <jet-button @click="addBlock">Add block</jet-button>
                </div>
              </div>
              <div class="col-span-12 md:col-span-3">
                <div v-for="preset in presets" class="px-0 md:px-3">
                  <div class="border border-slate-300 border-solid cursor-move list-group-item overflow-hidden
                  mb-[10px] p-[10px] rounded-[6px]
                  md:mb-4 md:p-5 md:rounded-2xl
                  ">
                    <div class="flex flex-col text-[14px]">
                      <a class="flex mb-[10px] text-[16px] md:text-xl md:mb-4 underline underline-offset-2 hover:no-underline hover:bg-orange-50"
                        :href="`/admin/work-item-presets/${preset.id}`">{{ preset.name }}</a>
                      <div class="flex justify-between">
                        <a class="text-slate-500 hover:text-slate-800 cursor-pointer"
                          :href="`/admin/work-item-presets/${preset.id}/create-block/${model.id}`">
                          Create block</a>
                      </div>
                    </div>
                    <div class="flex flex-row gap-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="tab === 2">
            <form @submit.prevent="submit">
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="Published" value="Published" />
                  <div class="mt-2 grid grid-cols-1 gap-4">
                    <label class="flex items-center">
                      <jet-checkbox :value="form.published" v-model:checked="form.published" />
                      <span class="ml-2 text-sm text-slate-600">Yes</span>
                    </label>
                  </div>
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="name" value="Name" />
                  <jet-input id="name" type="text" class="block w-full mt-1" v-model="form.name" autofocus />
                  <jet-input-error :message="form.errors.name" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="url" value="Url" />
                  <jet-input id="url" type="text" class="block w-full mt-1" v-model="form.url" autofocus :disabled="[
                    31, 12, 57, 25, 56, 58, 8, 11, 37, 59, 20, 38, 18, 60,
                    61, 40, 6, 42, 22, 39, 46, 43, 33, 55, 13, 36, 54, 10,
                    19, 63, 34, 64, 65, 35, 47, 9,
                  ].includes(parseInt(model.id))
                    " />
                  <jet-input-error :message="form.errors.url" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="main_tag" value="Next case" />
                  <select v-model="form.next" id="next"
                    class="border border-slate-300 focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50 rounded-md shadow-sm block w-full mt-1 px-4 py-2 outline-none">
                    <option :value="key" v-for="(name, key) in works">
                      {{ name }}
                    </option>
                  </select>
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="main_tag" value="Main tag" />
                  <select v-model="form.tag" id="main_tag"
                    class="border border-slate-300 focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50 rounded-md shadow-sm block w-full mt-1 px-4 py-2 outline-none">
                    <option :value="parseInt(key)" v-for="(name, key) in tags">
                      {{ name }}
                    </option>
                  </select>
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="p_top" value="Tags" />
                  <select v-model="form.tags" id="p_top" multiple
                    class="border border-slate-300 focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50 rounded-md shadow-sm block w-full mt-1 px-4 py-2 outline-none h-[300px]">
                    <option :value="parseInt(key)" v-for="(name, key) in tags">
                      {{ name }}
                    </option>
                  </select>

                  <div class="w-full mt-20 mb-20">
                    <!--										<pre>{{ form.tags }}</pre>-->

                    <template v-for="(item, key, index) in tagsTags">
                      <div class="grid grid-cols-2" v-if="form.tags.includes(parseInt(key))">
                        <div class="text-bold">
                          {{ getTagName(key) }}
                        </div>
                        <div>
                          <select v-model="tagsTags[key]" :id="`tags_tags_${key}`"
                            class="border border-slate-300 focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50 rounded-md shadow-sm block w-full mt-1 px-4 py-2 outline-none">
                            <template v-for="(name, keyV, index) in tags">
                              <option :value="parseInt(keyV)" v-if="
                                form.tags.includes(parseInt(keyV)) &&
                                key !== keyV
                              ">
                                {{ name }}
                              </option>
                            </template>
                          </select>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="cover_title" value="Cover title" />
                  <jet-input id="cover_title" type="text" class="block w-full mt-1" v-model="form.cover_title"
                    autofocus />
                  <jet-input-error :message="form.errors.cover_title" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4 grid grid-cols-6 gap-6 mt-24 mb-24">
                  <div class="col-span-6 md:col-span-3">
                    <div class="w-full text-lg mb-6">Desktop</div>

                    <div class="w-full mb-5">
                      <image-editor name="cover_vimeo" label="Cover image or video" desc="(min-size 1920×1080)"
                        :value="form.cover" :validation-rules="{
                          accept: [
                            'image/png',
                            'image/jpeg',
                            'image/gif',
                            'video/mp4',
                          ],
                          maxSizeInKB: 100000,
                        }" @update="onChangeUniversalNEW('cover', $event)"></image-editor>
                    </div>
                  </div>

                  <div class="col-span-6 md:col-span-3">
                    <div class="w-full text-lg mb-6">Mobile</div>

                    <div class="w-full mb-5">
                      <image-editor name="cover_small" label="Cover image or video" desc="(min-size 1080×1920)"
                        :value="form.cover_small" :validation-rules="{
                          accept: [
                            'image/png',
                            'image/jpeg',
                            'image/gif',
                            'video/mp4',
                          ],
                          maxSizeInKB: 100000,
                        }" @update="onChangeUniversalNEW('cover_small', $event)"></image-editor>
                    </div>
                  </div>

                  <div class="col-span-6">
                    <div class="w-full text-lg mb-6">Preloader</div>

                    <div class="w-full mb-5">
                      <jet-label for="cover_ratio_small" value="Background" />
                      <jet-input id="cover_ratio_small" type="text" class="block w-full mt-1"
                        v-model="form.first_screen_bg" autofocus />
                    </div>

                    <div class="w-full mb-5">
                      <jet-label for="cover_vimeo_small" value="Text color" />
                      <jet-input id="cover_vimeo_small" type="text" class="block w-full mt-1"
                        v-model="form.first_screen_color" autofocus />
                    </div>

                  </div>
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="bg_color" value="BG color" />
                  <input type="color" id="bg_color" name="bg_color"
                    class="border border-solid border-slate-300 h-24 w-24 mt-1" v-model="form.bg_color" />
                  <jet-input-error :message="form.errors.bg_color" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="scroll_bg" value="Scroll bg" />
                  <input type="color" id="scroll_bg" name="bg_color"
                    class="border border-solid border-slate-300 h-24 w-24 mt-1" v-model="form.scroll_bg" />
                  <jet-input-error :message="form.errors.scroll_bg" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="scroll_color" value="Scroll color" />
                  <input type="color" id="scroll_color" name="scroll_color"
                    class="border border-solid border-slate-300 h-24 w-24 mt-1" v-model="form.scroll_color" />
                  <jet-input-error :message="form.errors.scroll_color" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="cover_dark" value="Cover dark" />
                  <div class="mt-2 grid grid-cols-1 gap-4">
                    <label class="flex items-center">
                      <jet-checkbox :value="form.cover_dark" v-model:checked="form.cover_dark" />
                      <span class="ml-2 text-sm text-slate-600">Yes</span>
                    </label>
                  </div>
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="footer_dark" value="Footer dark" />
                  <div class="mt-2 grid grid-cols-1 gap-4">
                    <label class="flex items-center">
                      <jet-checkbox :value="form.footer_dark" v-model:checked="form.footer_dark" />
                      <span class="ml-2 text-sm text-slate-600">Yes</span>
                    </label>
                  </div>
                </div>

                <!-- HOME PAGE -->
                <div class="col-span-6 sm:col-span-4 text-xl mt-24">
                  Homepage
                </div>
                <div class="col-span-6 sm:col-span-4">

                  <image-editor name="home_cover_slot1" label="Cover image or video - 1x1" desc="(min-size 980×980)"
                    :value="form.home_cover_slot1" :validation-rules="{
                      accept: [
                        'image/png',
                        'image/jpeg',
                        'image/gif',
                        'video/mp4',
                      ],
                      maxSizeInKB: 100000,
                    }" @update="onChangeUniversalNEW('home_cover_slot1', $event)"></image-editor>
                </div>
                <div class="col-span-6 sm:col-span-4">

                  <image-editor name="home_cover_slot2" label="Cover image or video - landscape"
                    desc="(min-size 1900x950)" :value="form.home_cover_slot2" :validation-rules="{
                      accept: [
                        'image/png',
                        'image/jpeg',
                        'image/gif',
                        'video/mp4',
                      ],
                      maxSizeInKB: 100000,
                    }" @update="onChangeUniversalNEW('home_cover_slot2', $event)"></image-editor>
                </div>
                <div class="col-span-6 grid grid-cols-8 gap-6">
                  <div class="col-span-8 md:col-span-2">
                    <jet-label for="home_title_1280" value="Homepage title | 1280" />

                    <jet-textarea id="home_title_1280" type="text" class="block w-full mt-1"
                      v-model="form.home_title_1280" />
                  </div>

                  <div class="col-span-8 md:col-span-2">
                    <jet-label for="home_title_1024" value="Homepage title | 1024" />
                    <jet-textarea id="home_title_1024" type="text" class="block w-full mt-1"
                      v-model="form.home_title_1024" />
                  </div>

                  <div class="col-span-8 md:col-span-2">
                    <jet-label for="home_title_768" value="Homepage title | 768" />
                    <jet-textarea id="home_title_768" type="text" class="block w-full mt-1"
                      v-model="form.home_title_768" />
                  </div>

                  <div class="col-span-8 md:col-span-2">
                    <jet-label for="home_title_375" value="Homepage title | 375" />
                    <jet-textarea id="home_title_375" type="text" class="block w-full mt-1"
                      v-model="form.home_title_375" />
                  </div>
                </div>

                <!-- WORK -->
                <div class="col-span-6 sm:col-span-4 text-xl mt-24">Works</div>

                <div class="col-span-6 sm:col-span-4">

                  <image-editor name="work_cover_large" label="Works cover image or video | Large"
                    desc="(min-size 1000×1000)" :value="form.work_cover_large" :validation-rules="{
                      accept: [
                        'image/png',
                        'image/jpeg',
                        'image/gif',
                        'video/mp4',
                      ],
                      maxSizeInKB: 100000,
                    }" @update="onChangeUniversalNEW('work_cover_large', $event)"></image-editor>
                </div>

                <div class="col-span-6 grid grid-cols-12 gap-6">

                  <div class="col-span-12 md:col-span-3">
                    <jet-label for="works_title_large" value="Works title | Large" />
                    <jet-textarea id="works_title_large" type="text" class="block w-full mt-1"
                      v-model="form.works_title_large" />

                    <div class="w-full mt-4">
                      <jet-label for="works_title_large_tag_is_dark" value="Tag style dark" />
                      <div class="mt-2 grid grid-cols-1 gap-4">
                        <label class="flex items-center">
                          <jet-checkbox :value="form.works_title_large_tag_is_dark"
                            v-model:checked="form.works_title_large_tag_is_dark" />
                          <span class="ml-2 text-sm text-slate-600">Yes</span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="col-span-12 md:col-span-3">
                    <jet-label for="works_title_medium" value="Works title | Medium" />

                    <jet-textarea id="works_title_medium" type="text" class="block w-full mt-1"
                      v-model="form.works_title_medium" />

                    <div class="w-full mt-4">
                      <jet-label for="works_title_medium_tag_is_dark" value="Tag style dark" />
                      <div class="mt-2 grid grid-cols-1 gap-4">
                        <label class="flex items-center">
                          <jet-checkbox :value="form.works_title_medium_tag_is_dark" v-model:checked="form.works_title_medium_tag_is_dark
                            " />
                          <span class="ml-2 text-sm text-slate-600">Yes</span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="col-span-12 md:col-span-3">
                    <jet-label for="works_title_small" value="Works title | Small" />
                    <jet-textarea id="works_title_small" type="text" class="block w-full mt-1"
                      v-model="form.works_title_small" />

                    <div class="w-full mt-4">
                      <jet-label for="works_title_small_tag_is_dark" value="Tag style dark" />
                      <div class="mt-2 grid grid-cols-1 gap-4">
                        <label class="flex items-center">
                          <jet-checkbox :value="form.works_title_small_tag_is_dark"
                            v-model:checked="form.works_title_small_tag_is_dark" />
                          <span class="ml-2 text-sm text-slate-600">Yes</span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="col-span-12 md:col-span-3">
                    <jet-label for="works_bg_color" value="Works bg color" />
                    <input type="color" id="works_bg_color" name="works_bg_color"
                      class="border border-solid border-slate-300 h-24 w-24 mt-1" v-model="form.works_bg_color" />
                    <jet-input-error :message="form.errors.works_bg_color" class="mt-2" />
                  </div>
                </div>
              </div>

              <div class="flex items-center justify-start pt-8">
                <jet-button :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                  Save
                </jet-button>
                <jet-action-message :on="form.recentlySuccessful" class="ml-3">
                  Saved.
                </jet-action-message>
              </div>
            </form>
          </div>

          <div v-if="tab === 3">
            <template v-for="(item, index) in credits.inside">
              <div class="grid grid-cols-8 gap-6 mb-6">
                <div class="col-span-8 md:col-span-3">
                  <jet-label for="name" value="Name" />
                  <select v-model="item.id" id="guard_name"
                    class="border border-slate-300 focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50 rounded-md shadow-sm block w-full mt-1 px-4 py-2 outline-none">
                    <option disabled value="">Please select an option</option>
                    <option :value="key" v-for="(member, key) in teamMembers">
                      {{ member }}
                    </option>
                  </select>
                </div>

                <div class="col-span-8 md:col-span-4">
                  <jet-label for="name" value="Position" />
                  <jet-input id="name" type="text" class="block w-full mt-1" v-model="item.position" autofocus />
                </div>

                <div class="col-span-8 md:col-span-1">
                  <jet-label for="action" value="-" />
                  <jet-danger-button @click="removePerson('inside', index)" custom-class="mt-1">
                    remove
                  </jet-danger-button>
                </div>
              </div>
            </template>

            <div class="text-xl">Out</div>

            <template v-for="(item, index) in credits.outside">
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-12 md:col-span-2">
                  <jet-label for="company" value="Company" />
                  <jet-input id="company" type="text" class="block w-full mt-1" v-model="item.company" autofocus />
                </div>

                <div class="col-span-12 md:col-span-3">
                  <jet-label for="name" value="Name" />
                  <jet-input id="name" type="text" class="block w-full mt-1" v-model="item.name" autofocus />
                </div>

                <div class="col-span-12 md:col-span-3">
                  <jet-label for="position" value="Position" />
                  <jet-input id="position" type="text" class="block w-full mt-1" v-model="item.position" autofocus />
                </div>

                <div class="col-span-12 md:col-span-3">
                  <jet-label for="link" value="Link" />
                  <jet-input id="link" type="text" class="block w-full mt-1" v-model="item.link" autofocus />
                </div>

                <div class="col-span-12 md:col-span-1">
                  <jet-label for="action" value="-" />
                  <jet-danger-button @click="removePerson('outside', index)" custom-class="mt-1">
                    remove
                  </jet-danger-button>
                </div>
              </div>
            </template>

            <div class="flex items-center justify-start pt-8">
              <jet-button @click="saveTeamMembers"> Save </jet-button>
              <jet-action-message :on="form.recentlySuccessful" class="ml-3">
                Saved.
              </jet-action-message>
            </div>

          </div>

          <div v-if="tab === 4">
            <form @submit.prevent="submit">
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="meta_title" :require="true" value="Title" />
                  <jet-input id="meta_title" type="text" class="block w-full mt-1" v-model="form.meta_title"
                    autofocus />

                  <div v-if="typeof form.meta_title === 'string'" class="mt-2 flex text-xs text-slate-400">
                    <span v-if="
                      form.meta_title.length >= 30 &&
                      form.meta_title.length <= 90
                    " class="inline mr-2" :class="{
                      'text-yellow-500': form.meta_title.length > 60,
                      'text-green-500': form.meta_title.length <= 60,
                    }">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4">
                        <path
                          d="M1 8.25a1.25 1.25 0 112.5 0v7.5a1.25 1.25 0 11-2.5 0v-7.5zM11 3V1.7c0-.268.14-.526.395-.607A2 2 0 0114 3c0 .995-.182 1.948-.514 2.826-.204.54.166 1.174.744 1.174h2.52c1.243 0 2.261 1.01 2.146 2.247a23.864 23.864 0 01-1.341 5.974C17.153 16.323 16.072 17 14.9 17h-3.192a3 3 0 01-1.341-.317l-2.734-1.366A3 3 0 006.292 15H5V8h.963c.685 0 1.258-.483 1.612-1.068a4.011 4.011 0 012.166-1.73c.432-.143.853-.386 1.011-.814.16-.432.248-.9.248-1.388z" />
                      </svg>
                    </span>
                    <span v-else class="inline mr-2 text-slate-300">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4">
                        <path
                          d="M18.905 12.75a1.25 1.25 0 01-2.5 0v-7.5a1.25 1.25 0 112.5 0v7.5zM8.905 17v1.3c0 .268-.14.526-.395.607A2 2 0 015.905 17c0-.995.182-1.948.514-2.826.204-.54-.166-1.174-.744-1.174h-2.52c-1.242 0-2.26-1.01-2.146-2.247.193-2.08.652-4.082 1.341-5.974C2.752 3.678 3.833 3 5.005 3h3.192a3 3 0 011.342.317l2.733 1.366A3 3 0 0013.613 5h1.292v7h-.963c-.684 0-1.258.482-1.612 1.068a4.012 4.012 0 01-2.165 1.73c-.433.143-.854.386-1.012.814-.16.432-.248.9-.248 1.388z" />
                      </svg>
                    </span>

                    Your title should be between 30-60 characters, with a
                    maximum of 90 (currently &nbsp;<b>{{
                      form.meta_title.length
                    }}</b>&nbsp; characters)
                  </div>

                  <jet-input-error :message="form.errors.meta_title" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="meta_description" :require="true" value="Description" />
                  <jet-input id="meta_description" type="text" class="block w-full mt-1" v-model="form.meta_description"
                    autofocus />

                  <div class="mt-2 flex text-xs text-slate-400">
                    <template v-if="typeof form.meta_description === 'string'">
                      <span v-if="
                        form.meta_description.length >= 55 &&
                        form.meta_description.length <= 300
                      " class="inline mr-2" :class="{
                        'text-yellow-500': form.meta_description.length > 200,
                        'text-green-500': form.meta_description.length <= 200,
                      }">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4">
                          <path
                            d="M1 8.25a1.25 1.25 0 112.5 0v7.5a1.25 1.25 0 11-2.5 0v-7.5zM11 3V1.7c0-.268.14-.526.395-.607A2 2 0 0114 3c0 .995-.182 1.948-.514 2.826-.204.54.166 1.174.744 1.174h2.52c1.243 0 2.261 1.01 2.146 2.247a23.864 23.864 0 01-1.341 5.974C17.153 16.323 16.072 17 14.9 17h-3.192a3 3 0 01-1.341-.317l-2.734-1.366A3 3 0 006.292 15H5V8h.963c.685 0 1.258-.483 1.612-1.068a4.011 4.011 0 012.166-1.73c.432-.143.853-.386 1.011-.814.16-.432.248-.9.248-1.388z" />
                        </svg>
                      </span>
                      <span v-else class="inline mr-2 text-slate-300">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4">
                          <path
                            d="M18.905 12.75a1.25 1.25 0 01-2.5 0v-7.5a1.25 1.25 0 112.5 0v7.5zM8.905 17v1.3c0 .268-.14.526-.395.607A2 2 0 015.905 17c0-.995.182-1.948.514-2.826.204-.54-.166-1.174-.744-1.174h-2.52c-1.242 0-2.26-1.01-2.146-2.247.193-2.08.652-4.082 1.341-5.974C2.752 3.678 3.833 3 5.005 3h3.192a3 3 0 011.342.317l2.733 1.366A3 3 0 0013.613 5h1.292v7h-.963c-.684 0-1.258.482-1.612 1.068a4.012 4.012 0 01-2.165 1.73c-.433.143-.854.386-1.012.814-.16.432-.248.9-.248 1.388z" />
                        </svg>
                      </span>

                      Your description should be between 55 and 200 characters
                      long, with a maximum of 300 (currently &nbsp;<b>{{
                        form.meta_description.length
                      }}</b>&nbsp; characters)
                    </template>

                    <template v-else>
                      <span class="inline mr-2 text-slate-300">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4">
                          <path
                            d="M18.905 12.75a1.25 1.25 0 01-2.5 0v-7.5a1.25 1.25 0 112.5 0v7.5zM8.905 17v1.3c0 .268-.14.526-.395.607A2 2 0 015.905 17c0-.995.182-1.948.514-2.826.204-.54-.166-1.174-.744-1.174h-2.52c-1.242 0-2.26-1.01-2.146-2.247.193-2.08.652-4.082 1.341-5.974C2.752 3.678 3.833 3 5.005 3h3.192a3 3 0 011.342.317l2.733 1.366A3 3 0 0013.613 5h1.292v7h-.963c-.684 0-1.258.482-1.612 1.068a4.012 4.012 0 01-2.165 1.73c-.433.143-.854.386-1.012.814-.16.432-.248.9-.248 1.388z" />
                        </svg>
                      </span>

                      Your description should be between 55 and 200 characters
                      long, with a maximum of 300
                    </template>
                  </div>

                  <jet-input-error :message="form.errors.meta_description" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="meta_keywords" value="Keywords" />
                  <jet-input id="meta_keywords" type="text" class="block w-full mt-1" v-model="form.meta_keywords"
                    autofocus />
                  <jet-input-error :message="form.errors.meta_keywords" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">

                  <image-editor name="share_image" label="Share image" desc="(size 1200x630)" :value="form.share_image"
                    :validation-rules="{
                      accept: [
                        'image/png',
                        'image/jpeg',
                        'image/gif',
                        'video/mp4',
                      ],
                      maxSizeInKB: 100000,
                    }" @update="onChangeUniversal('share_image', $event)"></image-editor>

                </div>
              </div>

              <div class="flex items-center justify-start pt-8">
                <jet-button :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                  Save
                </jet-button>
                <jet-action-message :on="form.recentlySuccessful" class="ml-3">
                  Saved.
                </jet-action-message>
              </div>
            </form>
          </div>

          <div v-if="tab === 5">
            <ActivityLog modelClass="work" :modelId="model.id" />
          </div>

        </div>
      </div>
    </div>

    <jet-confirmation-modal :show="workItemBeingDeleted" @close="workItemBeingDeleted = null">
      <template #title> Removing block from a case </template>

      <template #content>
        Are you sure you want to remove this block?
      </template>

      <template #footer>
        <jet-secondary-button @click="workItemBeingDeleted = null">
          Cancel
        </jet-secondary-button>

        <jet-danger-button class="ml-2" type="link" :href="`/admin/work-items/${workItemBeingDeleted}/remove`">
          Remove
        </jet-danger-button>
      </template>
    </jet-confirmation-modal>

    <jet-confirmation-modal :show="workBeingDeleted" @close="workBeingDeleted = null">
      <template #title> Removing a case </template>

      <template #content> Are you sure you want to remove this case? </template>

      <template #footer>
        <jet-secondary-button @click="workBeingDeleted = null">
          Cancel
        </jet-secondary-button>

        <jet-danger-button class="ml-2" type="link" :href="`/admin/works/${workBeingDeleted}/remove`">
          Remove
        </jet-danger-button>
      </template>
    </jet-confirmation-modal>
  </app-layout>
</template>

<script setup>
import { computed, onMounted, onUpdated, reactive, ref } from 'vue';
import '@vuepic/vue-datepicker/dist/main.css';
import AppLayout from '@/Layouts/AppLayout.vue';
import { useForm } from '@inertiajs/inertia-vue3';
import JetButton from '@/Jetstream/Button.vue';
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetDangerButton from '@/Jetstream/DangerButton.vue';
import JetActionMessage from '@/Jetstream/ActionMessage';
import JetTextarea from '@/Jetstream/Textarea';
import draggable from 'vuedraggable';
import JetSecondaryButton from '@/Jetstream/SecondaryButton';
import JetCheckbox from '@/Jetstream/Checkbox';
import AppTabsLabel from '@/Components/Tabs/Label.vue';
import ImageEditor from '@/Components/ImageEditor.vue';
import AppUsefulLinks from '@/Components/usefulLinks.vue';
import ActivityLog from '@/Components/ActivityLog.vue';

const props = defineProps({
  model: Object,
  tags: Object,
  items: Object,
  presets: Object,
  teamMembers: Object,
  works: Object,
  prefixRoute: String,
  title: String,
  usefulLinks: Object,
});

const tab = ref(1);
const currentTab = (tabNumber) => (tab.value = tabNumber);

const form = useForm({
  tag: props.model.tag,
  tags_tags: props.model.tags_tags,

  name: props.model.name,
  meta_title: props.model.meta_title,
  meta_description: props.model.meta_description,
  meta_keywords: props.model.meta_keywords,
  url: props.model.url,
  tags: props.model.tags,
  published: props.model.published,
  published_at: props.model.published_at,

  cover: props.model.cover,
  cover_small: props.model.cover_small,
  cover_title: props.model.cover_title,
  cover_dark: props.model.cover_dark,
  footer_dark: props.model.footer_dark,
  cover_vimeo: props.model.cover_vimeo,
  cover_ratio: props.model.cover_ratio,
  cover_vimeo_small: props.model.cover_vimeo_small,
  cover_ratio_small: props.model.cover_ratio_small,
  bg_color: props.model.bg_color,
  scroll_bg: props.model.scroll_bg,
  scroll_color: props.model.scroll_color,

  share_image: props.model.share_image,

  home_cover_slot1: props.model.home_cover_slot1,
  home_cover_slot2: props.model.home_cover_slot2,

  works_bg_color: props.model.works_bg_color,
  work_cover_large: props.model.work_cover_large,
  work_cover_medium: props.model.work_cover_medium,
  work_cover_small: props.model.work_cover_small,

  works_title_large: props.model.works_title_large,
  works_title_medium: props.model.works_title_medium,
  works_title_small: props.model.works_title_small,

  works_title_large_tag_is_dark: props.model.works_title_large_tag_is_dark,
  works_title_medium_tag_is_dark: props.model.works_title_medium_tag_is_dark,
  works_title_small_tag_is_dark: props.model.works_title_small_tag_is_dark,

  home_title_1280: props.model.home_title_1280,
  home_title_1024: props.model.home_title_1024,
  home_title_768: props.model.home_title_768,
  home_title_375: props.model.home_title_375,

  first_screen_bg: props.model.first_screen_bg,
  first_screen_color: props.model.first_screen_color,
  next_cover_desk: props.model.next_cover_desk,
  next_cover_mob: props.model.next_cover_mob,

  next: props.model.next,
});

const list = ref(null);
const credits = reactive({
  inside: [],
  outside: [],
});

if (typeof props.model.credits !== 'undefined') {
  if (typeof props.model.credits.inside !== 'undefined') {
    for (let i in props.model.credits.inside) {
      let member = props.model.credits.inside[i];
      credits.inside.push(member);
    }
  }
  if (typeof props.model.credits.outside !== 'undefined') {
    for (let i in props.model.credits.outside) {
      let member = props.model.credits.outside[i];
      credits.outside.push(member);
    }
  }
}

onMounted(() => {
  list.value = props.items;
});

onUpdated(() => {
  list.value = props.items;
});

const enabled = ref(true);
const dragging = ref(false);

const removePerson = function (type, index) {
  for (const [key, value] of Object.entries(credits[type][index])) {
    credits[type][index][key] = null;
  }
};

const validationErrors = ref(null);
const main_image = ref(null);
const square_image = ref(null);
const horizontal_image = ref(null);
const html = ref(null);
const deleteApiTokenForm = ref(null);
const displayingToken = ref(false);
const managingPermissionsFor = ref(null);
const workBeingDeleted = ref(null);
const workItemBeingDeleted = ref(null);

const submit = function () {
  form.tags_tags = tagsTags.value;
  form.put(route(`${props.prefixRoute}.update`, props.model.id));
};

const sort = function () {
  list.value = list.value.sort((a, b) => a.order - b.order);
};

const addBlock = function () {
  axios
    .post(`/admin/work-items/`, { work_id: props.model.id })
    .then((response) => {
      console.log(response.data);
      window.location.reload();
    })
    .catch((error) => {
      console.log(error);
    });
};

const originalList = ref([]);

const saveOriginalOrder = () => {
  originalList.value = JSON.parse(JSON.stringify(list.value)); // Глубокая копия массива
};

const checkMove = () => {
  if (!window.confirm("Are you sure you want to change the order?")) {
    list.value = JSON.parse(JSON.stringify(originalList.value));
    return;
  }

  setTimeout(() => {
    const order = list.value.map((item) => item.id);
    axios
      .post(`/admin/work-items/${props.model.id}/sort`, { order })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, 100);
};

const onChange = function (img) {
  form.cover = img;
};

const onChangeUniversal = function (name, img) {
  form[name] = img;
};

const onChangeUniversalNEW = function (name, img) {
  console.log('--onChangeUniversal sss', img);
  form[name] = img.value;
};

const confirmWorkItemDeletion = function (token) {
  workItemBeingDeleted.value = token;
};

const confirmWorkDeletion = function (token) {
  workBeingDeleted.value = token;
};

const editNameBlock = function (text, id) {
  axios
    .post(`/admin/work-items/${id}/rename`, { name: text })
    .then((response) => {
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

const saveTeamMembers = function () {
  console.log(credits);
  axios
    .post(`/admin/works/${props.model.id}/set-team`, credits)
    .then((response) => {
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

const dragOptions = computed(() => {
  return {
    animation: 200,
    group: 'description',
    disabled: false,
    ghostClass: 'bg-orange-50',
  };
});

const getTagName = function (id) {
  return props.tags[id];
};

const tagsTags = ref({});

const initTags = function () {
  console.log('asd');
  for (let tag in props.tags) {
    if (typeof form.tags_tags[tag] !== 'undefined') {
      tagsTags.value[tag] = form.tags_tags[tag];
    } else {
      tagsTags.value[tag] = 0;
    }
  }

  form.tags_tags = tagsTags.value;
};

onMounted(() => {
  initTags();
});
</script>

<style scoped>
@import 'media-library-pro-styles';

.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}

/*

 */
input[type='color'] {
  -webkit-appearance: none;
}

input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type='color']::-webkit-color-swatch {
  border: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
