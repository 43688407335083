<template>
  <app-layout :title="``">
    <template #header>
      <div class="flex w-full justify-between items-center">
        <h2 class="flex flex-col md:flex-row md:items-center md:text-[24px] text-[14px] leading-none" v-html="title">
        </h2>
        <div class="flex gap-x-[5px] md:gap-x-[40px] justify-between">
          <jet-danger-button type="link" :href="`/admin/team-members/${model.id}/remove`">Remove</jet-danger-button>
        </div>
      </div>
    </template>

    <div class="mx-auto py-4">
      <div class="w-full">
        <nav class="border-b text-sm flex justify-start">
          <a :class="{
            'inline-block py-2 mr-4 text-slate-600 hover:text-slate-900': true,
            'border-b-2 border-orange-600 ': tab === 1,
          }" href="#" @click="currentTab(1)">Data</a>
          <a :class="{
            'inline-block py-2 mr-4 text-slate-600 hover:text-slate-900': true,
            'border-b-2 border-orange-600 ': tab === 2,
          }" href="#" @click="currentTab(2)">Activity</a>
        </nav>
        <div class="pt-10">
          <div v-if="tab === 1">
            <form @submit.prevent="submit">
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="name" value="Name" />
                  <jet-input id="name" type="text" class="block w-full mt-1" v-model="form.name" autofocus />
                  <jet-input-error :message="form.errors.name" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="link" value="Link" />
                  <jet-input id="link" type="text" class="block w-full mt-1" v-model="form.link" autofocus />
                  <jet-input-error :message="form.errors.link" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="p_top" value="Tags" />
                  <select v-model="form.tag_id" id="p_top"
                    class="border border-slate-300 focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50 rounded-md shadow-sm block w-full mt-1 px-4 py-2 outline-none">
                    <option :value="key" v-for="(name, key) in tags">
                      {{ name }}
                    </option>
                  </select>
                </div>

                <div class="col-span-6 sm:col-span-4">

                  <image-editor name="cover" label="Cover" desc="" :value="form.cover" :validation-rules="{
                    accept: ['image/png', 'image/jpeg', 'image/gif'],
                    maxSizeInKB: 100000,
                  }" @update="onChangeUniversalNEW('cover', $event)"></image-editor>

                </div>
              </div>
              <div class="flex items-center justify-start pt-8">
                <jet-button :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                  Save
                </jet-button>
                <jet-action-message :on="form.recentlySuccessful" class="ml-3">
                  Saved.
                </jet-action-message>
              </div>
            </form>
          </div>
          <div v-if="tab === 2">
            <ActivityLog modelClass="bundle-item" :modelId="model.id" />
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import { defineComponent, onMounted, onUpdated, ref } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import AppLayout from '@/Layouts/AppLayout.vue';
import { useForm } from '@inertiajs/inertia-vue3';
import JetButton from '@/Jetstream/Button.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetDangerButton from '@/Jetstream/DangerButton.vue';
import JetActionMessage from '@/Jetstream/ActionMessage';
import JetTextarea from '@/Jetstream/Textarea';
import { MediaLibraryAttachment } from '../../../../../vendor_custom/laravel-medialibrary-pro/resources/js/media-library-pro-vue3-attachment';
import { MediaLibraryCollection } from '../../../../../vendor_custom/laravel-medialibrary-pro/resources/js/media-library-pro-vue3-collection';
import draggable from 'vuedraggable';
import JetDialogModal from '@/Jetstream/DialogModal';
import JetSecondaryButton from '@/Jetstream/SecondaryButton';
import VueTagsInput from '@sipec/vue3-tags-input';
import JetCheckbox from '@/Jetstream/Checkbox';
import VueEasyLightbox from 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js';
import ActivityLog from '@/Components/ActivityLog.vue';
import ImageEditor from '@/Components/ImageEditor.vue';

export default defineComponent({
  components: {
    draggable,
    AppLayout,
    JetButton,
    JetFormSection,
    JetInput,
    JetInputError,
    JetLabel,
    JetDangerButton,
    JetActionMessage,
    JetTextarea,
    MediaLibraryAttachment,
    MediaLibraryCollection,
    JetDialogModal,
    JetSecondaryButton,
    Datepicker,
    VueTagsInput,
    JetCheckbox,
    VueEasyLightbox,
    ActivityLog,
    ImageEditor
  },

  setup(props) {
    const tab = ref(1);
    const currentTab = (tabNumber) => (tab.value = tabNumber);
    const form = useForm({
      name: props.model.name,
      link: props.model.link,
      cover: props.model.cover,
      tag_id: props.model.tag_id,
    });
    onMounted(() => { });
    onUpdated(() => { });
    const enabled = ref(true);
    const dragging = ref(false);

    const onChangeUniversalNEW = function (name, img) {
      form[name] = img.value;
    };
    return {
      form,
      tab,
      currentTab,
      onChangeUniversalNEW
    };
  },

  data() {
    return {
      validationErrors: this.$page.props.errors,
    };
  },

  props: {
    model: Object,
    prefixRoute: String,
    title: String,
    tags: Object,
  },

  methods: {
    submit() {
      this.form.put(route(`${this.prefixRoute}.update`, this.model.id));
    }
  },
});
</script>

<style>
@import 'media-library-pro-styles';

.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}

.thumb {
  width: 4rem !important;
  height: 4rem !important;
  object-fit: cover !important;
}

.media-library-thumb {
  width: 10rem !important;
  height: 10rem !important;
  margin: 4rem;
}
</style>
