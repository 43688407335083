<template>
  <app-layout :title="``">
    <template #header>
      <div class="flex w-full justify-between items-center">
        <h2 class="flex flex-col md:flex-row md:items-center md:text-[24px] text-[14px] leading-none" v-html="title">
        </h2>
        <div class="flex gap-x-[5px] md:gap-x-[40px] justify-between">

        </div>
      </div>
    </template>

    <div class="mx-auto py-4">
      <div class="flow-root">
        <div class="overflow-x-auto">
          <div class="inline-block min-w-full align-middle">
            <table class="min-w-full divide-y divide-slate-300">
              <thead>
                <tr>
                  <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0">
                    <span class="sr-only">Edit</span>
                  </th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900">
                    Title
                  </th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900">
                    Text
                  </th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900">
                    Url
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-slate-200 bg-white">
                <tr v-for="item in modelList.data" :key="item.id">
                  <td class="whitespace-nowrap py-2 px-0">
                    <div class="flex flex-row gap-[10px]">
                      <EditButton :href="route(`${prefixRoute}.show`, item.id)" />
                      <EditButton :href="route(`${prefixRoute}.remove`, item.id)" :icon="TrashIcon" />
                      <EditButton :href="route(`${prefixRoute}.verify`, item.id)" :icon="CheckIcon" />
                      <EditButton :href="route(`${prefixRoute}.attention`, item.id)" :icon="HeartIcon" />
                    </div>
                  </td>
                  <td class="py-2 pl-4 pr-3 text-sm text-slate-900 sm:pl-0">
                    <div class="max-w-[250px]">
                      <div v-html="item.title" class="w-full"></div>
                    </div>
                  </td>
                  <td class="px-2 py-2 text-sm text-slate-500">
                    <div class="max-w-[550px]">
                      <div v-html="item.text" class="w-full"></div>
                    </div>
                  </td>
                  <td class="px-2 py-2 text-sm">
                    <div class="max-w-[250px]">
                      <a :href="item.url" target="_blank" class="text-sky-500 cursor-pointer">
                        {{ getDomain(item.url) }}</a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-[1000px]">
      <pre v-if="modelList">{{ modelList.data }}</pre>
    </div>

  </app-layout>
</template>

<script setup>
import AppLayout from '@/Layouts/AppLayout.vue';
import EditButton from '@/Components/Tables/EditButton.vue';
import { TrashIcon, CheckIcon, HeartIcon } from '@heroicons/vue/outline';

defineProps({
  modelList: Object,
  prefixRoute: String,
  title: String
});

function getDomain(url) {
  if (!url) {
    return '-';
  }
  try {
    const newUrl = new URL(url);
    return newUrl.hostname.replace('www.', '');
  } catch (e) {
    return url;
  }
}
</script>

<style>
hlword {
  font-weight: bold;
  background-color: rgba(255, 255, 0, 0.2);
  display: inline-block;
}
</style>
