<template>
  <app-layout :title="``">
    <template #header>
      <div class="flex w-full justify-between items-center">
        <h2 class="flex flex-col md:flex-row md:items-center md:text-[24px] text-[14px] leading-none" v-html="title">
        </h2>
        <div class="flex gap-x-[5px] md:gap-x-[40px] justify-between">
          <app-useful-links :items="usefulLinks" />
          <a target="_self"
            class="h-[42px] cursor-pointer disabled:opacity-25 focus:border-red-700 focus:outline-none focus:ring focus:ring-red-200 font-light hover:bg-red-100 hover:border-red-600 inline-flex items-center justify-center px-4 rounded-md text-base text-red-500 tracking-widest transition"
            @click="confirmDeletion(model.id)">
            Remove
          </a>
        </div>
      </div>
    </template>

    <div class="mx-auto py-4">
      <div class="w-full">
        <nav class="border-b text-sm flex justify-start">
          <a :class="{
            'inline-block py-2 mr-4 text-slate-600 hover:text-slate-900': true,
            'border-b-2 border-orange-600 ': tab === 1,
          }" href="#" @click="currentTab(1)">Data</a>
          <a :class="{
            'inline-block py-2 mr-4 text-slate-600 hover:text-slate-900': true,
            'border-b-2 border-orange-600 ': tab === 2,
          }" href="#" @click="currentTab(2)">Activity</a>
        </nav>

        <div class="pt-10">
          <div v-if="tab === 1">
            <form @submit.prevent="submit">
              <div class="grid grid-cols-6 gap-6">

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="name" value="Name" />
                  <jet-input id="name" type="text" class="block w-full mt-1" v-model="form.name" autofocus />
                  <jet-input-error :message="form.errors.name" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="email" value="Email" />
                  <jet-input id="email" type="text" class="block w-full mt-1" v-model="form.email" autofocus />
                  <jet-input-error :message="form.errors.email" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="admin" value="Admin" />
                  <div class="mt-2 grid grid-cols-1 gap-4">
                    <label class="flex items-center">
                      <jet-checkbox :value="form.admin" v-model:checked="form.admin" />
                      <span class="ml-2 text-sm text-slate-600">Yes</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="flex items-center justify-start pt-8">
                <jet-button :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                  Save
                </jet-button>
                <jet-action-message :on="form.recentlySuccessful" class="ml-3">
                  Saved.
                </jet-action-message>
              </div>
            </form>
          </div>
          <div v-if="tab === 2">
            <ActivityLog modelClass="user" :modelId="model.id" />
          </div>
        </div>
      </div>
    </div>

    <jet-confirmation-modal :show="beingDeleted" @close="beingDeleted = null">
      <template #title> Removing a user </template>

      <template #content>
        Are you sure you want to remove this user?
      </template>

      <template #footer>
        <jet-secondary-button @click="beingDeleted = null">
          Cancel
        </jet-secondary-button>

        <jet-danger-button class="ml-2" type="link" :href="`/admin/users/${beingDeleted}/remove`">
          Remove
        </jet-danger-button>
      </template>
    </jet-confirmation-modal>

  </app-layout>
</template>

<script>
import { defineComponent, onMounted, onUpdated, ref, watch } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import AppLayout from '@/Layouts/AppLayout.vue';
import { useForm } from '@inertiajs/inertia-vue3';
import JetButton from '@/Jetstream/Button.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetDangerButton from '@/Jetstream/DangerButton.vue';
import JetActionMessage from '@/Jetstream/ActionMessage';
import JetTextarea from '@/Jetstream/Textarea';
import { MediaLibraryAttachment } from '../../../../../vendor_custom/laravel-medialibrary-pro/resources/js/media-library-pro-vue3-attachment';
import { MediaLibraryCollection } from '../../../../../vendor_custom/laravel-medialibrary-pro/resources/js/media-library-pro-vue3-collection';
import draggable from 'vuedraggable';
import JetDialogModal from '@/Jetstream/DialogModal';
import JetSecondaryButton from '@/Jetstream/SecondaryButton';
import VueTagsInput from '@sipec/vue3-tags-input';
import JetCheckbox from '@/Jetstream/Checkbox';
import VueEasyLightbox from 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js';
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue';
import ImageEditor from '@/Components/ImageEditor.vue';
import AppUsefulLinks from '@/Components/usefulLinks.vue';
import ActivityLog from '@/Components/ActivityLog.vue';

export default defineComponent({
  components: {
    ImageEditor,
    JetConfirmationModal,
    draggable,
    AppLayout,
    JetButton,
    JetFormSection,
    JetInput,
    JetInputError,
    JetLabel,
    JetDangerButton,
    JetActionMessage,
    JetTextarea,
    MediaLibraryAttachment,
    MediaLibraryCollection,
    JetDialogModal,
    JetSecondaryButton,
    Datepicker,
    VueTagsInput,
    JetCheckbox,
    VueEasyLightbox,
    AppUsefulLinks,
    ActivityLog
  },
  setup(props) {
    const tab = ref(1);
    const currentTab = (tabNumber) => (tab.value = tabNumber);
    const form = useForm({
      name: props.model.name,
      email: props.model.email,
      admin: props.model.admin,
      published: props.model.published,
    });
    onMounted(() => { });
    onUpdated(() => { });
    const enabled = ref(true);
    const dragging = ref(false);
    const beingDeleted = ref(null);
    const confirmDeletion = function (token) {
      beingDeleted.value = token;
    };

    const onChangeUniversalNEW = function (name, img) {
      form[name] = img.value;
    };

    return {
      form,
      tab,
      currentTab,
      beingDeleted,
      confirmDeletion,
      onChangeUniversalNEW,
    };
  },
  data() {
    return {
      validationErrors: this.$page.props.errors,
    };
  },
  props: {
    model: Object,
    prefixRoute: String,
    title: String,
    usefulLinks: Object,
  },
  methods: {
    submit() {
      this.form.put(route(`${this.prefixRoute}.update`, this.model.id));
    },
    onChangeUniversal(name, img) {
      this.form[name] = img;
      console.log(name, img);
    },
  },
});
</script>

<style>
@import 'media-library-pro-styles';

.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}

.thumb {
  width: 4rem !important;
  height: 4rem !important;
  object-fit: cover !important;
}

.media-library-thumb {
  width: 10rem !important;
  height: 10rem !important;
  margin: 4rem;
}
</style>
