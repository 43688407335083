<template>
  <app-layout :title="``">
    <template #header>
      <div class="flex w-full justify-between">
        <div class="flex">
          <h2 class="flex items-center md:text-[24px] text-[14px]" v-html="title"></h2>
        </div>
        <div></div>
      </div>
    </template>

    <div class="mx-auto py-4">
      <div class="w-full">
        <nav class="border-b text-sm flex justify-start">
          <a :class="{
            'inline-block py-2 mr-4 text-slate-600 hover:text-slate-900': true,
            'border-b-2 border-orange-600 ': tab === 1,
          }" href="#" @click="currentTab(1)">Content</a>
          <a :class="{
            'inline-block py-2 mr-4 text-slate-600 hover:text-slate-900': true,
            'border-b-2 border-orange-600 ': tab === 2,
          }" href="#" @click="currentTab(2)">Media</a>
          <a :class="{
            'inline-block py-2 mr-4 text-slate-600 hover:text-slate-900': true,
            'border-b-2 border-orange-600 ': tab === 3,
          }" href="#" @click="currentTab(3)">Texts</a>
          <a :class="{
            'inline-block py-2 mr-4 text-slate-600 hover:text-slate-900': true,
            'border-b-2 border-orange-600 ': tab === 4,
          }" href="#" @click="currentTab(4)">Settings</a>
        </nav>
        <div class="pt-10">
          <div v-if="tab === 1">
            <div class="w-full mb-[50px]">
              <label class="flex items-center">
                <jet-checkbox :value="isVisibleDeprecated" v-model:checked="isVisibleDeprecated" />
                <span class="ml-2 text-sm text-slate-600">Deprecated</span>
              </label>
            </div>

            <div v-for="mq in ['l', 'm', 's', 'xs']" class="mb-16 py-6 relative">
              <div :class="{
                'absolute font-bold h-8  text-white uppercase w-8 -top-8 -left-[1px] flex items-center justify-center': true,
                'bg-red-500': compMQ[mq] === null,
                'bg-green-500': compMQ[mq] !== null,
              }">
                <span>{{ mq }}</span>
              </div>

              <div class="flex flex-column gap-4 w-full mb-6">
                <div v-for="item in globalTypes" @click="tabMQ[mq] = item.key" :class="{
                  'px-2 py-1 bg-slate-100 rounded': true,
                  'hover:bg-slate-300': true,
                  'cursor-pointer border-slate-300': item.key !== tabMQ[mq],
                  'bg-orange-500 text-white': item.key === tabMQActive[mq],
                  'bg-slate-300 border-slate-700':
                    item.key === tabMQ[mq] && item.key !== tabMQActive[mq],
                }">
                  {{ item.name }}
                </div>
              </div>

              <div class="flex flex-wrap gap-6">
                <template v-for="item in adaptiveBlocks(mq)">
                  <div class="p-3 rounded-sm" :class="{
                    'bg-sky-50': item.published,
                    'bg-slate-50': !item.published,
                    hidden: !item.published && !isVisibleDeprecated,
                  }">
                    <div @click="activeAdaptiveBlock(mq, item)" :class="{
                      'border border-solid border-slate-300 p-2 w-full bg-white ': true,
                      'rounded-sm': true,
                      'cursor-pointer': true,
                      'ring ring-sky-500 ring-offset-4':
                        item.id === compMQ[mq],
                      'hover:ring hover:ring-sky-300 hover:ring-offset-4':
                        item.id !== compMQ[mq],
                      'grayscale pointer-events-none':
                        item.published === false,
                    }">
                      <div class="w-96">
                        <img :src="`https://works.shuka.design/${item.image}`" loading="lazy"
                          class="h-full object-contain opacity-50 grayscale hover:grayscale-0 hover:opacity-100"
                          alt="" />
                      </div>
                    </div>

                    <div class="mt-3 w-full">
                      <div class="font-bold mb-1 text-xs">
                        <a v-if="item.link" title="Link to Figma" target="_blank" class="underline hover:text-sky-600"
                          :href="item.link">{{ item.full_name }}</a>
                        <template v-else>
                          {{ item.full_name }}
                        </template>

                        <span v-if="item.published === false"
                          class="font-normal ml-[20px] text-[20px] text-red-500">Deprecated</span>
                      </div>
                    </div>
                  </div>
                </template>
              </div>

              <div class="w-full mt-4">
                <details>
                  <summary>Content sort</summary>

                  <draggable :list="orderItemsMQ[mq]" item-key="order" class="flex flex-column gap-4 w-full"
                    @end="orderItemsMQSort" v-bind="dragOptions">
                    <template #item="{ element }">
                      <div class="px-2 py-1 bg-slate-100 rounded hover:bg-slate-300 cursor-pointer border-slate-300">
                        {{ nameAtomByOrder(element) }}
                      </div>
                    </template>
                  </draggable>
                </details>

                <details class="mt-2">
                  <summary>Text sort</summary>
                  <draggable :list="orderCaptionsMQ[mq]" item-key="order" class="flex flex-column gap-4 w-full"
                    @end="orderCaptionsMQSort" v-bind="dragOptions">
                    <template #item="{ element }">
                      <div class="px-2 py-1 bg-slate-100 rounded hover:bg-slate-300 cursor-pointer border-slate-300">
                        {{ nameCaptionByOrder(element) }}
                      </div>
                    </template>
                  </draggable>
                </details>
              </div>
            </div>
          </div>

          <div v-if="tab === 2">
            <div v-for="(item, index) in items" class="mb-12 border border-slate-200 rounded-[8px] p-[10px] md:p-[20px]">
              <div class="text-3xl mb-2">{{ index + 1 }}</div>

              <form @submit.prevent="submit(index, item.id)">
                <div class="grid grid-cols-12 gap-6">
                  <div class="col-span-12 md:col-span-6 grid grid-cols-6 gap-[10px] md:gap-[20px]">
                    <div class="col-span-6">
                      <jet-label for="name" value="Name" />
                      <jet-input id="name" type="text" class="block w-full mt-1" v-model="formMedia[index].name"
                        autofocus />
                      <jet-input-error :message="formMedia[index].errors.name" class="mt-2" />
                    </div>

                    <div class="col-span-6">
                      <jet-label for="guard_name" value="Type" />
                      <select v-model="formMedia[index].type" id="type"
                        class="border border-slate-300 focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50 rounded-md shadow-sm block w-full mt-1 px-4 py-2 outline-none">
                        <option :value="name" v-for="(name, key) in [
                          'image',
                          'vimeo',
                          'video',
                          'iframe',
                        ]">
                          {{ name }}
                        </option>
                      </select>
                      <jet-input-error :message="formMedia[index].errors.link" class="mt-2" />
                    </div>

                    <div class="col-span-6">
                      <jet-label for="link" value="Link" />
                      <jet-input id="link" type="text" class="block w-full mt-1" v-model="formMedia[index].link"
                        autofocus />
                      <jet-input-error :message="formMedia[index].errors.link" class="mt-2" />
                    </div>

                    <div class="col-span-6">
                      <jet-label for="ratio" value="Ratio" />

                      <div class="text-xs my-2">
                        Example: 56.25 | 58.25 | 71.25 | 100
                      </div>

                      <jet-input id="ratio" type="text" class="block w-full mt-1" v-model="formMedia[index].ratio"
                        autofocus />
                      <jet-input-error :message="formMedia[index].errors.ratio" class="mt-2" />
                    </div>

                    <div class="col-span-6">
                      <jet-label for="media" value="Media" />

                      <image-editor name="media" label="Media" desc="" :value="formMedia[index].media"
                        :validation-rules="{
                          accept: [
                            'image/png',
                            'image/jpeg',
                            'image/gif',
                            'video/mp4',
                          ],
                          maxSizeInKB: 100000,
                        }" @update="onChangeImage($event, index, item.id)" />
                    </div>

                    <template v-if="formMedia[index].media !== null">
                      <template v-if="typeof formMedia[index].media[0] !== 'undefined'">
                        <template v-if="
                          formMedia[index].media[0].mime_type === 'video/mp4'
                        ">
                          <div class="col-span-6">
                            <jet-label for="video_placeholder" value="Video placeholder" />
                            <media-library-attachment name="video_placeholder" :initial-value="formMedia[index].video_placeholder
                              " @change="
                                onChangeImageVideoPlaceholder(
                                  $event,
                                  index,
                                  item.id
                                )
                                " />
                          </div>

                          <div class="col-span-6">
                            <jet-label for="video_loop" value="Video loop" />
                            <div class="mt-2 grid grid-cols-1 gap-4">
                              <label class="flex items-center">
                                <jet-checkbox :value="formMedia[index].video_loop"
                                  v-model:checked="formMedia[index].video_loop" />
                                <span class="ml-2 text-sm text-slate-600">Yes</span>
                              </label>
                            </div>
                          </div>

                          <div class="col-span-6">
                            <jet-label for="video_controls" value="Video controls" />
                            <div class="mt-2 grid grid-cols-1 gap-4">
                              <label class="flex items-center">
                                <jet-checkbox :value="formMedia[index].video_controls" v-model:checked="formMedia[index].video_controls
                                  " />
                                <span class="ml-2 text-sm text-slate-600">Yes</span>
                              </label>
                            </div>
                          </div>

                          <div class="col-span-6">
                            <jet-label for="video_autoplay" value="Video autoplay" />
                            <div class="mt-2 grid grid-cols-1 gap-4">
                              <label class="flex items-center">
                                <jet-checkbox :value="formMedia[index].video_autoplay" v-model:checked="formMedia[index].video_autoplay
                                  " />
                                <span class="ml-2 text-sm text-slate-600">Yes</span>
                              </label>
                            </div>
                          </div>

                          <div class="col-span-6">
                            <jet-label for="video_play_color" value="Play color" />
                            <input type="color" id="video_play_color" name="video_play_color"
                              class="border border-solid border-slate-300 h-24 w-24 mt-1"
                              v-model="formMedia[index].video_play_color" />
                            <jet-input-error :message="formMedia[index].errors.video_play_color
                              " class="mt-2" />
                          </div>
                        </template>
                      </template>
                    </template>
                  </div>

                  <div class="col-span-12 md:col-span-6 grid grid-cols-3 gap-6">
                    <div v-for="mediaItem in formMedia[index].media" class="col-span-3">
                      <img v-if="
                        [
                          'image/png',
                          'image/jpg',
                          'image/jpeg',
                          'image/gif',
                          'image/webp',
                        ].includes(mediaItem.mime_type)
                      " :src="mediaItem.original_url" alt="" class="w-full border border-solid border-slate-300 p-2" />

                      <video v-if="['mp4'].includes(mediaItem.extension)" :src="mediaItem.original_url" alt=""
                        class="w-full border border-solid border-slate-300 p-2"></video>
                    </div>
                  </div>
                </div>

                <div class="flex items-center justify-start pt-8">
                  <jet-button :class="{ 'opacity-25': formMedia[index].processing }"
                    :disabled="formMedia[index].processing">
                    Save
                  </jet-button>
                  <jet-action-message :on="formMedia[index].recentlySuccessful" class="ml-3">
                    Saved.
                  </jet-action-message>
                </div>
              </form>
            </div>
          </div>

          <div v-if="tab === 3">
            <div v-for="(item, index) in captions" class="mb-12 border border-slate-200 rounded-[8px] p-[10px] md:p-[20px]">
              <div class="text-3xl mb-2">{{ index + 1 }}</div>

              <form @submit.prevent="submitCaption(index, item.id)">
                <div class="grid grid-cols-6 gap-[10px] md:gap-[20px]">
                  <div class="col-span-6 sm:col-span-4">
                    <jet-label for="name" value="Name" />
                    <jet-input id="name" type="text" class="block w-full mt-1" v-model="formCaption[index].name"
                      autofocus />
                    <jet-input-error :message="formCaption[index].errors.name" class="mt-2" />
                  </div>

                  <div class="col-span-6 sm:col-span-4">
                    <jet-label for="text" value="Text" />
                    <jet-textarea id="text" type="text" class="block w-full mt-1" v-model="formCaption[index].text" />
                    <jet-input-error :message="formCaption[index].errors.text" class="mt-2" />
                  </div>
                </div>

                <div class="flex items-center justify-start pt-8">
                  <jet-button :class="{ 'opacity-25': formCaption[index].processing }"
                    :disabled="formCaption[index].processing">
                    Save
                  </jet-button>
                  <jet-action-message :on="formCaption[index].recentlySuccessful" class="ml-3">
                    Saved.
                  </jet-action-message>
                </div>
              </form>
            </div>
          </div>

          <div v-if="tab === 4">
            <form @submit.prevent="submitSettings">
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="name" value="Name" />
                  <jet-input id="name" type="text" class="block w-full mt-1" v-model="formSettings.name" autofocus />
                  <jet-input-error :message="formSettings.errors.name" class="mt-2" />
                </div>

                <div
                  class="border border-slate-300 col-span-6 grid grid-cols-3 h-24 justify-stretch overflow-y-auto rounded-md shadow-sm sm:col-span-4 text-3xl">
                  <div class="bg-slate-900 flex items-center justify-center text-center text-white/70 cursor-pointer"
                    @click="changeTheme('dark')">
                    <span class="mr-2 text-2xl text-white" v-if="theme === 'dark'">👑</span>
                    Dark
                  </div>

                  <div class="bg-white flex items-center justify-center text-center bg-slate-900 cursor-pointer"
                    @click="changeTheme('light')">
                    <span class="mr-2 text-2xl" v-if="theme === 'light'">👑</span>
                    Light
                  </div>

                  <div class="border-l border-l-slate-300 flex items-center justify-between cursor-pointer"
                    @click="changeTheme('custom')">
                    <div class="flex h-full items-center px-8 w-full" :style="{
                      color: formSettings.text_color,
                      background: formSettings.bg_color,
                    }">
                      <span class="mr-2 text-2xl" v-if="theme === 'custom'">👑</span>
                      Custom
                    </div>

                    <div class="flex flex-col gap-2 px-2">
                      <div class="relative border-2 border-black/10 color-picker h-[30px] rounded-full w-[30px]">
                        <input class="color-picker__control" type="color" v-model="formSettings.text_color" />
                      </div>

                      <div class="relative border-2 border-black/10 color-picker h-[30px] rounded-full w-[30px]">
                        <input class="color-picker__control" type="color" v-model="formSettings.bg_color" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="p_top" value="Padding top" />
                  <select v-model="formSettings.p_top" id="p_top"
                    class="border border-slate-300 focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50 rounded-md shadow-sm block w-full mt-1 px-4 py-2 outline-none">
                    <option :value="name" v-for="(name, key) in [
                      'none',
                      'small',
                      'medium',
                      'large',
                      'extra-large',
                    ]">
                      {{ name }}
                    </option>
                  </select>
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="p_bottom" value="Padding bottom" />
                  <select v-model="formSettings.p_bottom" id="p_bottom"
                    class="border border-slate-300 focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50 rounded-md shadow-sm block w-full mt-1 px-4 py-2 outline-none">
                    <option :value="name" v-for="(name, key) in [
                      'none',
                      'small',
                      'medium',
                      'large',
                      'extra-large',
                    ]">
                      {{ name }}
                    </option>
                  </select>
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="m_top" value="Margin top" />
                  <select v-model="formSettings.m_top" id="m_top"
                    class="border border-slate-300 focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50 rounded-md shadow-sm block w-full mt-1 px-4 py-2 outline-none">
                    <option :value="name" v-for="(name, key) in [
                      'none',
                      'small',
                      'medium',
                      'large',
                      'extra-large',
                    ]">
                      {{ name }}
                    </option>
                  </select>
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="m_bottom" value="Margin bottom" />
                  <select v-model="formSettings.m_bottom" id="m_bottom"
                    class="border border-slate-300 focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50 rounded-md shadow-sm block w-full mt-1 px-4 py-2 outline-none">
                    <option :value="name" v-for="(name, key) in [
                      'none',
                      'small',
                      'medium',
                      'large',
                      'extra-large',
                    ]">
                      {{ name }}
                    </option>
                  </select>
                </div>

              </div>

              <div class="flex items-center justify-start pt-8">
                <jet-button :class="{ 'opacity-25': formSettings.processing }" :disabled="formSettings.processing">
                  Save
                </jet-button>
                <jet-action-message :on="formSettings.recentlySuccessful" class="ml-3">
                  Saved.
                </jet-action-message>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import { defineComponent, onMounted, onUpdated, reactive, ref } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import AppLayout from '@/Layouts/AppLayout.vue';
import { useForm } from '@inertiajs/inertia-vue3';
import JetButton from '@/Jetstream/Button.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetDangerButton from '@/Jetstream/DangerButton.vue';
import JetActionMessage from '@/Jetstream/ActionMessage';
import JetTextarea from '@/Jetstream/Textarea';
import { MediaLibraryAttachment } from '../../../../../vendor_custom/laravel-medialibrary-pro/resources/js/media-library-pro-vue3-attachment';
import { MediaLibraryCollection } from '../../../../../vendor_custom/laravel-medialibrary-pro/resources/js/media-library-pro-vue3-collection';
import draggable from 'vuedraggable';
import JetDialogModal from '@/Jetstream/DialogModal';
import JetSecondaryButton from '@/Jetstream/SecondaryButton';
import VueTagsInput from '@sipec/vue3-tags-input';
import JetCheckbox from '@/Jetstream/Checkbox';
import VueEasyLightbox from 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js';
import ImageEditor from '@/Components/ImageEditor.vue';

export default defineComponent({
  components: {
    draggable,
    AppLayout,
    JetButton,
    JetFormSection,
    JetInput,
    JetInputError,
    JetLabel,
    JetDangerButton,
    JetActionMessage,
    JetTextarea,
    MediaLibraryAttachment,
    MediaLibraryCollection,
    JetDialogModal,
    JetSecondaryButton,
    Datepicker,
    VueTagsInput,
    JetCheckbox,
    VueEasyLightbox,
    ImageEditor,
  },
  setup(props) {
    const mqList = ['l', 'm', 's', 'xs'];
    const tab = ref(1);
    const currentTab = (tabNumber) => (tab.value = tabNumber);
    onUpdated(() => { });
    const formSettings = useForm({
      name: props.model.name,
      p_top: props.model.p_top,
      p_bottom: props.model.p_bottom,
      m_top: props.model.m_top,
      m_bottom: props.model.m_bottom,
      bg_color: props.model.bg_color,
      //
      text_color: props.model.text_color,
      is_dark: props.model.is_dark,
      //
      theme: null,
    });
    //         'p_top', 'p_bottom', 'm_top', 'm_bottom', 'bg_color'
    const list = ref(null);
    const enabled = ref(true);
    const dragging = ref(false);
    const tabMQ = reactive({
      l: null,
      m: null,
      s: null,
      xs: null,
    });
    const tabMQActive = reactive({
      l: false,
      m: false,
      s: false,
      xs: false,
    });
    const compMQ = reactive({
      l: null,
      m: null,
      s: null,
      xs: null,
    });
    const orderItemsMQ = reactive({
      l: null,
      m: null,
      s: null,
      xs: null,
    });
    const orderCaptionsMQ = reactive({
      l: null,
      m: null,
      s: null,
      xs: null,
    });
    const activeTabsByBlockId = function (mq, id) {
      for (let i in props.globalTypes) {
        const a = props.globalTypes[i];
        if (a['items_parent_code'].includes(id)) {
          tabMQActive[mq] = a['key'];
          tabMQ[mq] = a['key'];
        }
      }
    };
    const formMedia = reactive({
      0: null,
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
      6: null,
      7: null,
      8: null,
      9: null,
    });
    const formCaption = reactive({
      0: null,
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
      6: null,
      7: null,
      8: null,
      9: null,
    });
    //
    const theme = ref(null);
    const changeTheme = function (value) {
      theme.value = value;
      if (value === 'light') {
        formSettings.is_dark = false;
      }
      if (value === 'dark') {
        formSettings.is_dark = true;
      }
      if (value === 'custom') {
        formSettings.is_dark = false;
      }
    };

    const isVisibleDeprecated = ref(false);

    //
    onMounted(() => {
      console.log('props.items', props.items);
      console.log('props', props);
      list.value = props.items;
      for (let i in mqList) {
        if (props.model[`comp_${mqList[i]}`] !== null) {
          compMQ[mqList[i]] = props.model[`comp_${mqList[i]}`];
          activeTabsByBlockId(mqList[i], compMQ[mqList[i]]);
          orderItemsMQ[mqList[i]] = props.model[`cont_${mqList[i]}`];
          orderCaptionsMQ[mqList[i]] = props.model[`capt_${mqList[i]}`];
        }
      }
      for (let i = 0; i < 10; i++) {
        formMedia[i] = useForm({
          name: props.items[i].name,
          type: props.items[i].type,
          link: props.items[i].link,
          media: props.items[i].media,
          ratio: props.items[i].ratio,
          video_placeholder: props.items[i].video_placeholder,
          video_loop: props.items[i].video_loop,
          video_controls: props.items[i].video_controls,
          video_autoplay: props.items[i].video_autoplay,
          video_play_color: props.items[i].video_play_color,
        });
      }
      for (let i = 0; i < 10; i++) {
        formCaption[i] = useForm({
          name: props.captions[i].name,
          text: props.captions[i].text,
        });
      }
      /*
comp_l: 121
comp_m: 132
comp_s: 143
comp_xs: 159
       */
      if (props.model.is_dark === true) {
        changeTheme('dark');
      } else {
        if (props.model.bg_color !== null && props.model.text_color !== null) {
          changeTheme('custom');
        } else {
          changeTheme('light');
        }
      }
    });
    return {
      formSettings,
      formMedia,
      formCaption,
      list,
      enabled,
      dragging,
      mqList,
      tabMQ,
      compMQ,
      tabMQActive,
      orderItemsMQ,
      orderCaptionsMQ,
      //System
      tab,
      currentTab,
      theme,
      changeTheme,

      isVisibleDeprecated,
    };
  },
  data() {
    return {
      validationErrors: this.$page.props.errors,
      main_image: null,
      square_image: null,
      horizontal_image: null,
      html: '',
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'bg-orange-50',
      };
    },
    adaptiveBlocks() {
      let self = this;
      return (mq) => {
        const globalTypesFormatted = self.globalTypes.filter((item) => {
          return (
            item.key === self.tabMQ[mq] && typeof item.items[mq] !== 'undefined'
          );
        });
        if (globalTypesFormatted.length > 0) {
          return globalTypesFormatted[0].items[mq];
        } else {
          return [];
        }
      };
    },
    nameAtomByOrder() {
      let self = this;
      return (order) => {
        const a = self.items.filter((item) => {
          return item.order === order;
        });
        if (a.length > 0) {
          return a[0].name;
        } else {
          return 'no name';
        }
      };
    },
    nameCaptionByOrder() {
      let self = this;
      return (order) => {
        const a = self.captions.filter((item) => {
          return item.order === order;
        });
        if (a.length > 0) {
          return a[0].name;
        } else {
          return 'no name';
        }
      };
    },
  },
  props: {
    model: Object,
    items: Object,
    captions: Object,
    globalTypes: Object,
    // mediaFiles: String,
    // colorPaletteMediaFiles: String,
    prefixRoute: String,
    title: String,
  },
  methods: {
    submit(index, id) {
      this.formMedia[index].put(route(`admin::work-item-atoms.update`, id));
    },
    submitCaption(index, id) {
      this.formCaption[index].put(
        route(`admin::work-item-captions.update`, id)
      );
    },
    submitSettings(index) {
      if (this.theme === 'dark' || this.theme === 'light') {
        this.formSettings.bg_color = null;
        this.formSettings.text_color = null;
      }

      this.formSettings.put(route(`admin::work-items.update`, this.model.id));
    },
    //destroy () {
    //	this.$inertia.delete(route(`${this.prefixRoute}.destroy`, this.model.id))
    //},
    sort() {
      this.list = this.list.sort((a, b) => a.order - b.order);
    },
    checkMove: function (e) {
      window.console.log('Future index: ' + e.draggedContext.futureIndex);
    },
    orderItemsMQSort() {
      let self = this;
      console.log(self.orderItemsMQ);
      // window.console.log('Future index: ' + e.draggedContext.futureIndex)
      setTimeout(() => {
        axios
          .post(
            `/admin/work-items/${self.model.id}/sort-atoms`,
            self.orderItemsMQ
          )
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }, 100);
    },
    orderCaptionsMQSort() {
      let self = this;
      console.log(self.orderCaptionsMQ);
      // window.console.log('Future index: ' + e.draggedContext.futureIndex)
      setTimeout(() => {
        axios
          .post(
            `/admin/work-items/${self.model.id}/sort-captions`,
            self.orderCaptionsMQ
          )
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }, 100);
    },
    activeAdaptiveBlock(mq, item) {
      let self = this;
      if (self.compMQ[mq] === item.id) {
        self.compMQ[mq] = null;
        self.tabMQActive[mq] = null;
      } else {
        self.compMQ[mq] = item.id;
        self.tabMQActive[mq] = self.tabMQ[mq];
      }
      self.saveMQ();
    },
    saveMQ() {
      let self = this;
      console.log(`/admin/work-items/${this.model.id}/mq`);
      axios
        .post(`/admin/work-items/${this.model.id}/mq`, self.compMQ)
        .then((response) => {
          console.log(response.data);
        });
    },
    onChangeImage(image, index, id) {
      this.formMedia[index].media = image.value;
      // this.formMedia[index].put(route(`admin::work-item-atoms.update`, id))
      // window.location.reload()
    },
    onChangeImageVideoPlaceholder(image, index, id) {
      this.formMedia[index].video_placeholder = image.value;
    },
  },
});
</script>

<style scoped>
@import '../../../../../vendor_custom/laravel-medialibrary-pro/resources/js/media-library-pro-styles';

.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
</style>
