<template>
  <app-layout :title="``">
    <template #header>
      <div class="flex w-full justify-between items-center">
        <h2 class="flex flex-col md:flex-row md:items-center md:text-[24px] text-[14px] leading-none" v-html="title"></h2>
        <div class="flex gap-x-[5px] md:gap-x-[40px] justify-between">
          <app-useful-links :items="usefulLinks" />
          <a :href="route(`${prefixRoute}.create`)">
            <jet-button>Create</jet-button>
          </a>
        </div>
      </div>
    </template>

    <div class="mx-auto py-4">
      <div class="flow-root">
        <div class="overflow-x-auto">
          <div class="inline-block min-w-full align-middle">
            <table class="min-w-full divide-y divide-slate-300">
              <thead>
                <tr>
                  <th class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0">
                    <span class="sr-only">Edit</span>
                  </th>
                  <th class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900">
                    Cover
                  </th>
                  <th class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900">
                    Name
                  </th>
                  <th class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900">
                    Position
                  </th>
                  <th class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900">
                    Published
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-slate-200 bg-white">
                <tr v-for="item in teamMembersPublished" :key="item.id">
                  <td class="whitespace-nowrap py-2 pl-0 pr-3 text-sm text-slate-900 w-[60px]">
                    <a :href="route(`${prefixRoute}.show`, item.id)"
                      class="bg-slate-50 text-slate-500 inline-flex hover:bg-slate-200 hover:text-slate-800 items-center justify-end p-3 rounded">
                      <PencilIcon class="h-5 w-5" aria-hidden="true" />
                    </a>
                  </td>
                  <td class="whitespace-nowrap px-2 py-2">
                    <img v-if="item.image" :src="item.image" loading="lazy"
                      class="h-[70px] w-[130px] object-cover border border-slate-200 rounded-[4px] box-content"
                      alt="Cover Image" />
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-900">
                    <div class="max-w-[200px] truncate overflow-hidden" :title="item.name">
                      {{ item.name }}
                    </div>
                    <div v-if="item.url" class="text-xs text-slate-600">
                      <a :href="item.url" :title="item.url" class="text-sky-600 hover:text-sky-900">{{
                        getDomain(item.url)
                        }}</a>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-500">
                    <div class="max-w-[300px] truncate overflow-hidden" :title="item.position">
                      {{ item.position }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm">
                    <div class="inline-flex">
                      <span v-if="item.published" class="inline mr-2" title="Published">
                        <EyeIcon class="h-5 w-5 text-green-500" aria-hidden="true" />
                      </span>
                      <span v-else class="inline mr-2" title="Not Published">
                        <EyeOffIcon class="h-5 w-5 text-slate-600" aria-hidden="true" />
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <app-sort-card :items="sortItems" :path="sortItemsURL" />

    <div class="w-full mt-[10px] md:mt-[20px]">
      <ActivityLogGroup modelClass="team-member" />
    </div>

  </app-layout>
</template>

<script setup>
import { ref } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import AppSortCard from '@/Components/Sort/SortCard.vue';
import AppUsefulLinks from '@/Components/usefulLinks.vue';
import PencilIcon from '@heroicons/vue/outline/PencilIcon';
import EyeOffIcon from '@heroicons/vue/outline/EyeOffIcon';
import EyeIcon from '@heroicons/vue/outline/EyeIcon';
import axios from 'axios';
import ActivityLogGroup from '@/Components/ActivityLogGroup.vue';

const props = defineProps({
  modelList: Object,
  sortItems: Object,
  sortItemsURL: String,
  prefixRoute: String,
  title: String,
  usefulLinks: Object,
});

const teamMembersPublished = ref(props.modelList);
const orderTeamMembersSaved = ref(false);

const orderTeamMembersSave = () => {
  axios
    .post(route('admin::team-members.sort-item'), {
      items: teamMembersPublished.value,
    })
    .then((response) => {
      console.log(response.data);
      orderTeamMembersSaved.value = true;
      setTimeout(() => {
        orderTeamMembersSaved.value = false;
      }, 3000);
    })
    .catch((error) => {
      console.log(error);
    });
};

function getDomain(url) {
  if (!url) {
    return '-';
  }
  try {
    const newUrl = new URL(url);
    return newUrl.hostname.replace('www.', '');
  } catch (e) {
    return url;
  }
}
</script>
