<template>
  <app-layout :title="``">
    <template #header>
      <div class="flex w-full justify-between items-center">
        <h2 class="flex flex-col md:flex-row md:items-center md:text-[24px] text-[14px] leading-none" v-html="title"></h2>
        <div class="flex gap-x-[5px] md:gap-x-[40px] justify-between">
          <app-useful-links :items="usefulLinks" />
          <a :href="route(`${prefixRoute}.create`)">
            <jet-button>Create</jet-button>
          </a>
        </div>
      </div>
    </template>

    <div class="mx-auto py-4">
      <div class="flow-root">
        <div class="overflow-x-auto">
          <div class="inline-block min-w-full align-middle">
            <table class="min-w-full divide-y divide-slate-300">
              <thead>
                <tr>
                  <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0">
                    <span class="sr-only">Edit</span>
                  </th>
                  <th scope="col"
                    class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-slate-900 sm:pl-0">
                    Cover
                  </th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900">
                    Name
                  </th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900">
                    Description
                  </th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900">
                    URL
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-slate-200 bg-white">
                <tr v-for="item in modelList.data" :key="item.id" :class="{ 'opacity-50': !item.published }">
                  <td class="whitespace-nowrap py-2 px-0">
                    <EditButton :href="route(`${prefixRoute}.show`, item.id)" />
                  </td>
                  <td class="whitespace-nowrap px-2 py-2">
                    <img v-if="item.image" :src="item.image" loading="lazy"
                      class="h-[70px] w-[130px] object-cover border border-slate-200 rounded-[4px] box-content"
                      alt="Cover Image" />
                  </td>
                  <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-slate-900 sm:pl-0">
                    <div class="max-w-[250px] truncate overflow-hidden" :title="item.name">
                      {{ item.name }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-500">
                    <div class="max-w-[250px] truncate overflow-hidden" :title="item.desc">
                      {{ item.desc }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-900">
                    <div class="max-w-[200px] truncate overflow-hidden">
                      <a v-if="item.url" :href="item.url" :title="item.url" class="text-sky-600 hover:text-sky-900">{{
                        getDomain(item.url) }}</a>
                      <span v-else>-</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <app-sort-card :items="sortItems" :path="sortItemsURL" />

    <div class="w-full mt-[10px] md:mt-[20px]">
      <ActivityLogGroup modelClass="press-kit-item" />
    </div>

  </app-layout>
</template>

<script setup>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import AppSortCard from '@/Components/Sort/SortCard.vue';
import AppUsefulLinks from '@/Components/usefulLinks.vue';
import EditButton from '@/Components/Tables/EditButton.vue';
import ActivityLogGroup from '@/Components/ActivityLogGroup.vue';

const props = defineProps({
  modelList: Object,
  sortItems: Object,
  sortItemsURL: String,
  prefixRoute: String,
  title: String,
  usefulLinks: Object,
});

function getDomain(url) {
  if (!url) {
    return '-';
  }
  try {
    const newUrl = new URL(url);
    return newUrl.hostname.replace('www.', '');
  } catch (e) {
    return url;
  }
}
</script>
