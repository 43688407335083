<template>
  <div class="flex flex-col items-center w-full">
    <div class="flex gap-4 mb-4 w-full">
      <input v-model="search" placeholder="Search media..." class="border p-2 rounded w-full" />
      <select v-model="selectedModel" class="border p-2 rounded">
        <option value="">All Models</option>
        <option v-for="model in models" :key="model" :value="model">
          {{ model }}
        </option>
      </select>
      <select v-model="selectedType" class="border p-2 rounded">
        <option value="">All Types</option>
        <option value="image">Images</option>
        <option value="video">Videos</option>
        <option value="vimeo">Vimeo</option>
      </select>
      <select v-model="selectedResolution" class="border p-2 rounded">
        <option value="">All Resolutions</option>
        <option value="640x480">640 x 480</option>
        <option value="800x600">800 x 600</option>
        <option value="1024x768">1024 x 768</option>
      </select>
      <select v-model="selectedExtension" class="border p-2 rounded">
        <option value="">All Extensions</option>
        <option value="jpg">JPG</option>
        <option value="png">PNG</option>
        <option value="jpeg">JPEG</option>
        <option value="gif">GIF</option>
        <option value="mp4">MP4</option>
      </select>
      <button @click="refreshMedia" class="bg-sky-500 text-white px-4 py-2 rounded">
        Refresh
      </button>
    </div>
    <div class="overflow-auto w-full">
      <div class="grid grid-cols-2 md:grid-cols-6 gap-4 w-full">
        <template v-for="(item, index) in displayedMedia" :key="item ? item.id : 'skeleton-' + index">
          <MediaCard v-if="item" :item="item" />
          <div v-else class="border p-2 rounded-[8px] bg-slate-200 animate-pulse" style="aspect-ratio: 1/1;"></div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import { useWindowScroll } from '@vueuse/core';
import axios from 'axios';
import MediaCard from './MediaCard.vue';

const media = ref([]);
const models = ref([]);
const loading = ref(false);
const search = ref('');
const selectedModel = ref('');
const selectedType = ref('');
const selectedResolution = ref('');
const selectedExtension = ref('');

const page = ref(1);
const hasMore = ref(true);
const placeholderCount = 50;

const displayedMedia = computed(() => {
  if (loading.value && hasMore.value) {
    return [...media.value, ...Array(placeholderCount).fill(null)];
  }
  return media.value;
});

const { y } = useWindowScroll();

const fetchMedia = async () => {
  if (loading.value || !hasMore.value) return;
  loading.value = true;
  try {
    const response = await axios.get('/admin/view-media', {
      params: {
        search: search.value,
        page: page.value,
        model: selectedModel.value,
        type: selectedType.value,
        resolution: selectedResolution.value,
        extension: selectedExtension.value
      }
    });
    if (response.data.data.length === 0) {
      hasMore.value = false;
    } else {
      media.value = [...media.value, ...response.data.data];
      page.value++;
    }
  } catch (error) {
    console.error('Error fetching media:', error);
  } finally {
    loading.value = false;
  }
};

const fetchModels = async () => {
  try {
    const response = await axios.get('/admin/view-media/models');
    models.value = response.data;
  } catch (error) {
    console.error('Error fetching models:', error);
  }
};

const refreshMedia = () => {
  media.value = [];
  page.value = 1;
  hasMore.value = true;
  fetchMedia();
};

onMounted(() => {
  fetchMedia();
  fetchModels();
});

watch(y, (newY) => {
  const documentHeight = document.documentElement.scrollHeight;
  const windowHeight = window.innerHeight;
  if (newY + windowHeight >= documentHeight - 100 && hasMore.value) {
    fetchMedia();
  }
});
</script>
