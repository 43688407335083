<template>
  <a
    v-if="type === 'link'"
    :href="href"
    :target="target"
    :class="[classButton]"
  >
    <slot></slot>
  </a>
  <button v-else :type="type" :class="classButton">
    <slot></slot>
  </button>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    type: {
      type: String,
      default: 'submit',
    },
    href: {
      type: String,
    },
    target: {
      type: String,
      default: '_self',
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    classButton() {
      let result = {
        'border border-1 disabled:opacity-25 cursor-pointer': true,
        'focus:border-red-700 focus:outline-none focus:ring focus:ring-red-200': true,
        'hover:bg-red-100 hover:border-red-600 inline-flex items-center justify-center px-4 rounded-md text-red-500 text-base': true,
        'tracking-widest transition': true,
        'font-light': true,
        'h-[42px]': true
      };
      if (typeof this.customClass !== 'undefined') {
        result[this.customClass] = true;
      }
      return result;
    },
  },
});
</script>
