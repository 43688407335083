<template>
  <button :type="type" class="h-[42px] px-[10px] rounded-[4px] text-[14px] md:px-[14px] md:rounded-[8px] md:text-[16px]" :class="classButton">
    <slot></slot>
  </button>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  type: {
    type: String,
    default: 'button',
  },
  customClass: {
    type: String,
    default: '',
  },
});

const classButton = computed(() => {
  return {
    'border border-1 disabled:opacity-25 focus:border-slate-700 focus:outline-none focus:ring focus:ring-slate-200 hover:bg-slate-100 hover:border-slate-600 inline-flex items-center justify-center text-slate-500 tracking-widest transition': true,
    [props.customClass]: props.customClass !== '',
  };
});
</script>
