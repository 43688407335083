<template>
  <app-layout :title="``">
    <template #header>
      <div class="flex w-full justify-between items-center">
        <h2 class="flex flex-col md:flex-row md:items-center md:text-[24px] text-[14px] leading-none" v-html="title"></h2>
        <div class="flex gap-x-[5px] md:gap-x-[40px] justify-between">
          <app-useful-links :items="usefulLinks" />
        </div>
      </div>
    </template>
  </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import { useForm } from '@inertiajs/inertia-vue3';
import JetButton from '@/Jetstream/Button.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetDangerButton from '@/Jetstream/DangerButton.vue';
import JetActionMessage from '@/Jetstream/ActionMessage';
import JetTextarea from '@/Jetstream/Textarea';
import AppUsefulLinks from '@/Components/usefulLinks.vue';

export default defineComponent({
  components: {
    AppLayout,
    JetButton,
    JetFormSection,
    JetInput,
    JetInputError,
    JetLabel,
    JetDangerButton,
    JetActionMessage,
    JetTextarea,
    AppUsefulLinks,
  },
  setup() {
    const form = useForm({
      name: null,
    });
    return { form };
  },
  props: {
    model: Object,
    prefixRoute: String,
    title: String,
    titleSection: String,
    descSection: String,
    usefulLinks: Object,
  },
  methods: {
    submit() {
      this.form.post(route(`${this.prefixRoute}.store`));
    },
  },
});
</script>
