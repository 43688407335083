<template>
    <app-layout :title="``">

        <div class="mx-auto py-4">

            <pre>{{ props }}</pre>

        </div>

    </app-layout>
</template>

<script setup>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
// import BrandMonitoringDashboard from '@/Components/BrandMonitoring/BrandMonitoringDashboard.vue';

const props = defineProps({
    data: Object,
    title: String,
    prefixRoute: String
});
</script>