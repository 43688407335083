<template>
  <app-layout :title="``">
    <template #header>
      <div class="flex w-full justify-between items-center">
        <h2 class="flex flex-col md:flex-row md:items-center md:text-[24px] text-[14px] leading-none" v-html="title">
        </h2>
        <div class="flex gap-x-[5px] md:gap-x-[40px] justify-between">
          <app-useful-links :items="usefulLinks" />
          <jet-danger-button type="link" :href="route(`${prefixRoute}.remove`, model.id)">Remove</jet-danger-button>
        </div>
      </div>
    </template>

    <div class="mx-auto py-4">
      <div class="w-full">
        <nav class="border-b text-sm flex justify-start">
          <a :class="{
            'inline-block py-2 mr-4 text-slate-600 hover:text-slate-900': true,
            'border-b-2 border-orange-600 ': tab === 1,
          }" href="#" @click="currentTab(1)">Data</a>
          <a :class="{
            'inline-block py-2 mr-4 text-slate-600 hover:text-slate-900': true,
            'border-b-2 border-orange-600 ': tab === 2,
          }" href="#" @click="currentTab(2)">Activity</a>
        </nav>
        <div class="pt-10">
          <div v-if="tab === 1">
            <div class="rounded-md bg-red-50 p-4 mb-12" v-if="validationErrors && validationErrors.error_message">
              <div class="flex">
                <div class="flex-shrink-0">
                  <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                  <h3 class="text-sm font-medium text-red-800">
                    {{ validationErrors.error_message }}
                  </h3>
                  <div class="mt-2 text-sm text-red-700">
                    <ul role="list" class="list-disc space-y-1 pl-5">
                      <li v-for="item in JSON.parse(validationErrors.items)" :key="item">
                        {{ item }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <form @submit.prevent="submit">
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="source_name" value="Name" />
                  <jet-input id="source_name" type="text" class="block w-full mt-1" v-model="form.name" autofocus />
                  <jet-input-error :message="form.errors.name" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="year" value="Year" />
                  <jet-input id="year" type="text" class="block w-full mt-1" v-model="form.year" autofocus />
                  <jet-input-error :message="form.errors.year" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="nomination" value="Nomination" />
                  <jet-input id="nomination" type="text" class="block w-full mt-1" v-model="form.nomination"
                    autofocus />
                  <jet-input-error :message="form.errors.nomination" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="value" value="Value" />
                  <jet-input id="value" type="text" class="block w-full mt-1" v-model="form.value" autofocus />
                  <jet-input-error :message="form.errors.value" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="link" value="Link" />
                  <jet-input id="link" type="text" class="block w-full mt-1" v-model="form.link" autofocus />
                  <jet-input-error :message="form.errors.link" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="case_link" value="Work link" />
                  <jet-input id="case_link" type="text" class="block w-full mt-1" v-model="form.case_link" autofocus />
                  <jet-input-error :message="form.errors.case_link" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="case_name" value="Work name" />
                  <jet-input id="case_name" type="text" class="block w-full mt-1" v-model="form.case_name" autofocus />
                  <jet-input-error :message="form.errors.case_name" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="sort" value="Sort" />
                  <jet-input id="sort" type="text" class="block w-full mt-1" v-model="form.sort" autofocus />
                  <jet-input-error :message="form.errors.sort" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <jet-label for="published" value="Published" />
                  <div class="mt-2 grid grid-cols-1 gap-4">
                    <label class="flex items-center">
                      <jet-checkbox :value="form.published" v-model:checked="form.published" />
                      <span class="ml-2 text-sm text-slate-600">Yes</span>
                    </label>
                  </div>
                </div>

                <div class="col-span-6 sm:col-span-4">

                  <image-editor name="cover" label="Award icon" desc="" :value="form.cover" :validation-rules="{
                    accept: ['image/png', 'image/jpeg', 'image/gif'],
                    maxSizeInKB: 100000,
                  }" @update="onChangeUniversal('cover', $event)"></image-editor>

                </div>
              </div>
              <div class="flex items-center justify-start pt-8">
                <jet-button :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                  Save
                </jet-button>
                <jet-action-message :on="form.recentlySuccessful" class="ml-3">
                  Saved.
                </jet-action-message>
              </div>
            </form>

            <div v-for="(awards, caseName) in groupedAwards" :key="caseName" class="mt-10">
              <h3 class="text-xl">{{ caseName }}</h3>
              <draggable v-model="groupedAwards[caseName]" @end="updateOrder(caseName)">
                <div v-for="award in awards" :key="award.id" class="p-2 border rounded mb-2">
                  {{ award.name }} ({{ award.year }})
                </div>
              </draggable>
            </div>
          </div>
          <div v-if="tab === 2">
            <ActivityLog modelClass="award" :modelId="model.id" />
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import { useForm } from '@inertiajs/inertia-vue3';
import { Inertia } from '@inertiajs/inertia';
import draggable from 'vuedraggable';
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetDangerButton from '@/Jetstream/DangerButton.vue';
import JetActionMessage from '@/Jetstream/ActionMessage.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import AppUsefulLinks from '@/Components/usefulLinks.vue';
import ImageEditor from '@/Components/ImageEditor.vue';
import ActivityLog from '@/Components/ActivityLog.vue';

const props = defineProps({
  model: Object,
  prefixRoute: String,
  title: String,
  error_message: String,
  success_message: String,
  usefulLinks: Object,
});

const tab = ref(1);
const form = useForm({
  name: props.model.name,
  year: props.model.year,
  nomination: props.model.nomination,
  value: props.model.value,
  link: props.model.link,
  case_link: props.model.case_link,
  case_name: props.model.case_name,
  published: props.model.published,
  sort: props.model.sort,
  cover: props.model.cover,
});

const awardsArray = Array.isArray(props.model.awards) ? props.model.awards : [];

const groupedAwards = ref(groupByCaseAndYear(awardsArray));
const validationErrors = reactive(null);

const currentTab = (tabNumber) => {
  tab.value = tabNumber;
};

const updateOrder = (caseName) => {
  groupedAwards.value[caseName].forEach((award, index) => {
    award.sort_work = index + 1;
    Inertia.put(
      route(`${props.prefixRoute}.update`, award.id),
      { sort_work: award.sort_work },
      { preserveState: true }
    );
  });
};

const submit = () => {
  if (!props?.prefixRoute || !props?.model?.id) {
    console.error("Missing required props:", { prefixRoute: props?.prefixRoute, modelId: props?.model?.id });
    return;
  }

  form.put(route(`${props.prefixRoute}.update`, props.model.id));
};

const onChangeUniversal = function (name, img) {
  form[name] = img.value;
};

function groupByCaseAndYear(awards) {
  const grouped = {};
  awards.forEach((award) => {
    if (!grouped[award.case_name]) grouped[award.case_name] = [];
    grouped[award.case_name].push(award);
  });
  return grouped;
}
</script>

<style>
@import 'media-library-pro-styles';

.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}

.thumb {
  width: 4rem !important;
  height: 4rem !important;
  object-fit: cover !important;
}

.media-library-thumb {
  width: 10rem !important;
  height: 10rem !important;
  margin: 4rem;
}
</style>
