<template>
  <app-layout title="API Tokens">
    <template #header>
      <h2 class="flex items-center md:text-[24px] text-[14px]">API Tokens</h2>
    </template>

    <div>
      <div class="mx-auto py-10">
        <api-token-manager
          :tokens="tokens"
          :available-permissions="availablePermissions"
          :default-permissions="defaultPermissions"
        />
      </div>
    </div>
  </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import ApiTokenManager from '@/Pages/API/Partials/ApiTokenManager.vue';
import AppLayout from '@/Layouts/AppLayout.vue';

export default defineComponent({
  props: ['tokens', 'availablePermissions', 'defaultPermissions'],
  components: {
    ApiTokenManager,
    AppLayout,
  },
});
</script>
