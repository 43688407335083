<template>
  <app-layout :title="title">
    <template #header>
      <div class="flex w-full justify-between items-center">
        <h2 class="flex flex-col md:flex-row md:items-center md:text-[24px] text-[14px] leading-none" v-html="title"></h2>
        <div class="flex gap-x-2 md:gap-x-10">
          <app-useful-links :items="usefulLinks" />
          <button
            class="h-[42px] px-4 rounded-md text-red-500 border border-red-500 hover:bg-red-100 transition"
            @click="confirmDeletion(model.id)"
          >
            Удалить
          </button>
        </div>
      </div>
    </template>

    <div class="mx-auto py-4">

      <div class="w-full">
        <nav class="border-b text-sm flex justify-start">
          <a
            :class="{
              'inline-block py-2 mr-4 text-slate-600 hover:text-slate-900': true,
              'border-b-2 border-orange-600': tab === 1,
            }"
            href="#"
            @click.prevent="currentTab(1)"
          >
            Data
          </a>
          <a
            :class="{
              'inline-block py-2 mr-4 text-slate-600 hover:text-slate-900': true,
              'border-b-2 border-orange-600': tab === 2,
            }"
            href="#"
            @click.prevent="currentTab(2)"
          >
            System
          </a>
        </nav>

        <div class="pt-10">
          <div v-if="tab === 1">
            <form @submit.prevent="submit">
              <div class="grid grid-cols-6 gap-6">
                
                <div class="col-span-6">
                  <jet-label for="name" value="" />
                  <jet-input id="name" type="text" class="block w-full mt-1" v-model="form.name" autofocus />
                  <jet-input-error :message="form.errors.name" class="mt-2" />
                </div>

              </div>

              <div class="flex items-center justify-start pt-8">
                <jet-button :disabled="form.processing">Save</jet-button>
                <jet-action-message :on="form.recentlySuccessful" class="ml-3">Saved.</jet-action-message>
              </div>

            </form>
          </div>
          <div v-if="tab === 2">
            <pre class="bg-red-500 text-white size-[700px]">{{ props }}</pre>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>


<script setup>
import { ref } from 'vue';
import { useForm } from '@inertiajs/inertia-vue3';
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetActionMessage from '@/Jetstream/ActionMessage.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import AppUsefulLinks from '@/Components/usefulLinks.vue';

const props = defineProps({
  model: Object,
  prefixRoute: String,
  title: String,
  usefulLinks: Object,
});

const tab = ref(1)
const currentTab = (tabNumber) => (tab.value = tabNumber);

const beingDeleted = ref(null);
const confirmDeletion = (id) => {
  beingDeleted.value = id;
};

const form = useForm({ ...props.model });

const submit = () => {
  form.put(route(`${props.prefixRoute}.update`, props.model.id));
};

const deleteQuery = () => {
  form.delete(route(`${props.prefixRoute}.destroy`, props.model.id), {
    onSuccess: () => (beingDeleted.value = null),
  });
};
</script>