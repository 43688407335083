<template>
    <div class="w-full text-[14px]">
        <div v-if="!isOpen" @click="toggleOpen"
            class="w-full items-center px-4 py-2 cursor-pointer bg-slate-50 border border-transparent rounded-md text-base text-slate-800 hover:bg-slate-300 focus:outline-none">
            <span class="text-base text-slate-800">Activity</span>
        </div>
        <div v-else class="w-full">
            <div @click="toggleOpen"
                class="w-full items-center flex justify-between px-4 py-2 cursor-pointer bg-slate-50 border border-transparent rounded-md text-base text-slate-800 hover:bg-slate-300 focus:outline-none">
                <span class="text-slate-800">Activity</span>
                <span class="text-slate-500">Close</span>
            </div>
            <div class="mt-[10px] md:mt-[20px]">
                <ActivityLog :modelClass="modelClass" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import ActivityLog from '@/Components/ActivityLog.vue'

defineProps({
    modelClass: {
        type: String,
        required: true
    }
})

const isOpen = ref(false)

const toggleOpen = () => {
    isOpen.value = !isOpen.value
}
</script>