<template>
  <div :style="{ backgroundColor: item.custom_properties.hex }" style="aspect-ratio: 1/1;"
    class="flex flex-col items-center border p-[6px] rounded-[8px] shadow w-full relative group overflow-hidden">



    <div v-if="item.mime_type.startsWith('image/')"
      class="hidden group-hover:block absolute bg-black bg-opacity-25 left-[8px] px-[10px] py-[2px] rounded-[3px] text-[10px] text-white top-[8px] z-50">
      {{ item.mime_type === 'image/gif' ? 'Image - gif' : 'Image' }}
    </div>
    <div v-else-if="item.mime_type.startsWith('video/')"
      class="hidden group-hover:block absolute bg-black bg-opacity-25 left-[8px] px-[10px] py-[2px] rounded-[3px] text-[10px] text-white top-[8px] z-50">
      Video
    </div>

    <div v-if="item.preview_url" @click="showInfo">
      <div v-if="item.mime_type === 'image/gif'"
        class="absolute inset-0 bg-cover bg-center z-40 pointer-events-none 
        transition-all duration-500 transform origin-center 
        opacity-0 group-hover:opacity-100
        scale-90 group-hover:scale-100"
        :style="{ backgroundImage: 'url(' + item.original_url + ')' }">
      </div>
      <img :src="item.preview_url" alt="Preview" loading="lazy"
        class="w-full h-auto rounded transition-opacity duration-500"
        :class="{ 'opacity-0': !imageLoaded, 'opacity-100': imageLoaded }" @load="imageLoaded = true" />
    </div>

    <JetDialogModal :show="isModalVisible" @close="isModalVisible = false">
      <template #title>Details</template>
      <template #content>
        <div v-if="item.mime_type.startsWith('image/')" class="w-full">
          <img :src="item.original_url" alt="Full Image" class="w-full h-auto rounded" />
        </div>
        <div v-else-if="item.mime_type.startsWith('video/')" class="w-full">
          <video :src="item.original_url" controls class="w-full h-auto rounded"></video>
        </div>

        <pre>{{ props }}</pre>

        <div class="mt-4">
          <h3 class="font-semibold">Custom Properties:</h3>
          <pre>{{ JSON.stringify(item.custom_properties, null, 2) }}</pre>
        </div>

        <div class="mt-4">
          <h3 class="font-semibold">Model Information:</h3>
          <p>{{ modelInfo }}</p>
        </div>

        <div v-if="isPredefinedModel" class="mt-4">
          <h3 class="font-semibold">Additional Information:</h3>
          <p>Extra details related to this model will be shown here.</p>
        </div>
      </template>

      <template #footer>
        <JetSecondaryButton @click="isModalVisible = false">Close</JetSecondaryButton>
      </template>
    </JetDialogModal>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import JetDialogModal from '@/Jetstream/DialogModal';
import JetSecondaryButton from '@/Jetstream/SecondaryButton';

const props = defineProps({
  item: Object,
});

const predefinedModels = [
  "App\\Models\\WorkItemAtom",
  "App\\Models\\Work",
  "App\\Models\\TeamMember",
  "App\\Models\\Publication",
  "App\\Models\\Event",
  "App\\Models\\Newsletter",
  "App\\Models\\BundleItem",
  "App\\Models\\Setting",
  "App\\Models\\News",
  "App\\Models\\Award",
  "App\\Models\\Page",
  "App\\Models\\PressKitItem",
  "App\\Models\\VacancyItem",
];

const isModalVisible = ref(false);
const imageLoaded = ref(false);

const modelInfo = computed(() => props.item.model_type || 'Unknown model');

const isPredefinedModel = computed(() => predefinedModels.includes(props.item.model));

const showInfo = () => {
  isModalVisible.value = true;
};
</script>
