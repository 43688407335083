<template>
  <app-layout :title="``">
    <template #header>
      <div class="flex w-full justify-between items-center">
        <h2 class="flex flex-col md:flex-row md:items-center md:text-[24px] text-[14px] leading-none" v-html="title"></h2>
        <div class="flex gap-x-[5px] md:gap-x-[40px] justify-between">
          <jet-button custom-class="ml-2" @click="saveSettings" :disabled="saveSettingsLoading">
            <template v-if="saveSettingsLoading">Wait...</template>
            <template v-else>Save</template>
          </jet-button>
        </div>
      </div>
    </template>

    <div class="mx-auto py-4">
      <div class="w-full">
        <nav class="border-b text-sm flex justify-start">
          <a :class="{
            'inline-block py-2 mr-4 text-slate-600 hover:text-slate-900': true,
            'border-b-2 border-orange-600 ': tab === 1,
          }" href="#" @click="currentTab(1)">Homepage</a>
        </nav>
        <div class="pt-10">
          <div v-if="tab === 1">
            <div v-for="setting in settings.homepage" class="mb-12">
              <div class="font-semibold mb-2">{{ setting.title }}</div>

              <template v-if="['homepage__works'].includes(setting.name)">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6">
                    <vue-tags-input v-model="materialsHomePageTagText" :tags="setting.data.value"
                      :autocomplete-items="materialsHomePageAutocompleteItems" :add-only-from-autocomplete="true"
                      placeholder="Add work" @tags-changed="materialsHomePageUpdate" />

                    <draggable :animation="300" ghost-class="ghost-card" group="items"
                      class="grid grid-cols-6 gap-1 mt-4" :list="setting.data.value" handle=".handle" itemKey="name">
                      <template #item="{ element, index }">
                        <div :class="{
                          'col-span-6 text-base mt-2 border p-2 cursor-grabbing	flex justify-between': true,
                          'bg-orange-50 border-orange-200': [0, 1].includes(
                            index
                          ),
                          'bg-sky-50 border-sky-200': [2].includes(index),
                          'bg-red-50 border-red-200': [3, 4].includes(index),
                          'bg-green-50 border-green-200': [5].includes(index),
                          'bg-purple-50 border-purple-200': [6, 7].includes(
                            index
                          ),
                          'bg-neutral-50 border-neutral-200 opacity-50 hover:opacity-100':
                            [
                              8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
                              20,
                            ].includes(index),
                        }">
                          <span :class="{
                            'truncate': true,
                            'handle': true
                          }">{{ element.text }}</span>

                          <div>
                            <span class="opacity-50 truncate" v-if="[0, 1].includes(index)"># Simple v1 line</span>
                            <span class="opacity-50 truncate" v-if="[2].includes(index)"># First full width line (before
                              news)</span>
                            <span class="opacity-50 truncate" v-if="[5].includes(index)"># Second full width line
                              (before history
                              x4)</span>
                            <span class="opacity-50 truncate" v-if="[3, 4].includes(index)"># Simple v2 line (after
                              comics)</span>
                            <span class="opacity-50 truncate" v-if="[6, 7].includes(index)"># Simple v2 line</span>

                            <a :href="`/admin/materials/${element.id}`" class="hidden md:inline ml-6 mr-2"
                              target="_blank">Ссылка</a>
                          </div>
                        </div>
                      </template>
                    </draggable>
                  </div>
                </div>
              </template>

              <template v-if="
                [
                  'homepage__fs_position',
                  'homepage__fs_title',
                  'homepage__fs_btn_text',
                  'homepage__fs_btn_link',
                ].includes(setting.name)
              ">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 md:col-span-3">
                    <textarea
                      class="block w-full mt-1 px-4 py-2 outline-none border border-slate-300 focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50 rounded-sm shadow-sm min-h-[100px]"
                      v-model="setting.data.value" />
                  </div>
                </div>
              </template>

              <template v-if="
                [
                  'homepage__fs_content_1280',
                  'homepage__fs_content_1024',
                  'homepage__fs_content_768',
                  'homepage__fs_content_375',
                  'homepage__first_banner',
                ].includes(setting.name)
              ">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 md:col-span-3">
                    <image-editor name="cover" label="" desc="" :value="images[setting.name]" :validation-rules="{
                      accept: [
                        'image/png',
                        'image/jpeg',
                        'image/gif',
                        'video/mp4',
                      ],
                      maxSizeInKB: 100000,
                    }" @update="onChangeUniversalNEW(setting.name, $event)" />
                  </div>
                </div>
              </template>

              <template v-if="
                [
                  'homepage__disable_news',
                  'homepage__disable_title',
                ].includes(setting.name)
              ">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 md:col-span-3">
                    <label class="flex items-center">
                      <jet-checkbox :value="setting.data.value" v-model:checked="setting.data.value" />
                      <span class="ml-2 text-sm text-slate-600">Yes</span>
                    </label>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div v-if="tab === 2">
            <div v-for="setting in settings.menu" class="mb-24">
              <div class="font-semibold mb-2">{{ setting.title }}</div>
              <template v-if="['header__menu', 'footer__menu'].includes(setting.name)">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 md:col-span-3" v-for="item in setting.data.value">

                    <div class="grid grid-cols-6 gap-3">
                      <div class="col-span-6 md:col-span-3">
                        <jet-input v-model="item.name" id="title" type="text" class="block w-full mt-1" />
                      </div>
                      <div class="col-span-3">
                        <jet-input v-model="item.link" id="title" type="text" class="block w-full mt-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="['header__rubrics'].includes(setting.name)">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6">
                    <vue-tags-input v-model="rubricsTopMenuTagText" :tags="setting.data.value"
                      :autocomplete-items="rubricsTopMenuAutocompleteItems" :add-only-from-autocomplete="true"
                      :placeholder="'Добавить тег'" @tags-changed="rubricsTopMenuUpdate" />

                    <draggable :animation="100" ghost-class="ghost-card" group="items"
                      class="grid grid-cols-6 gap-1 mt-4" :list="setting.data.value" itemKey="name">
                      <template #item="{ element, index }">
                        <div class="col-span-6 text-xs mt-2 bg-neutral-50 border border-neutral-100 p-2">
                          {{ element.text }}
                        </div>
                      </template>
                    </draggable>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div v-if="tab === 3">
            <div v-for="setting in settings.admin" class="mb-24">
              <div class="font-semibold mb-2">{{ setting.title }}</div>

              <template v-if="['admin__per_page'].includes(setting.name)">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 md:col-span-3">
                    <jet-input v-model="setting.data.value" id="title" type="text" class="block w-full mt-1" />
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div v-if="tab === 4">
            <!-- <pre>{{ settings.all }}</pre> -->

            <div v-for="setting in settings.all" class="mb-24">
              <div class="font-semibold mb-2">{{ setting.title }}</div>

              <template v-if="['all__js_client'].includes(setting.name)">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 md:col-span-3">
                    <textarea
                      class="block w-full mt-1 px-4 py-2 outline-none border border-slate-300 focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50 rounded-sm shadow-sm min-h-[100px]"
                      v-model="setting.data.value" />
                  </div>
                </div>
              </template>

              <template v-if="
                ['all__copyright', 'all__footer_text'].includes(setting.name)
              ">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 md:col-span-3">
                    <textarea
                      class="block w-full mt-1 px-4 py-2 outline-none border border-slate-300 focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50 rounded-sm shadow-sm min-h-[100px]"
                      v-model="setting.data.value" />
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import { defineComponent, onUpdated, reactive, ref } from 'vue';
import { usePage, useForm } from '@inertiajs/inertia-vue3';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetDangerButton from '@/Jetstream/DangerButton.vue';
import JetActionMessage from '@/Jetstream/ActionMessage';
import JetTextarea from '@/Jetstream/Textarea';
import JetCheckbox from '@/Jetstream/Checkbox';
import { MediaLibraryAttachment } from 'media-library-pro-vue3-attachment';
import { MediaLibraryCollection } from 'media-library-pro-vue3-collection';
import draggable from 'vuedraggable';
import JetDialogModal from '@/Jetstream/DialogModal';
import JetSecondaryButton from '@/Jetstream/SecondaryButton';
import VueTagsInput from '@sipec/vue3-tags-input';
import { BadgeCheckIcon } from '@heroicons/vue/outline';
import ImageEditor from '@/Components/ImageEditor.vue';

export default defineComponent({
  components: {
    draggable,
    AppLayout,
    JetButton,
    JetFormSection,
    JetInput,
    JetInputError,
    JetLabel,
    JetDangerButton,
    JetActionMessage,
    JetTextarea,
    JetCheckbox,
    MediaLibraryAttachment,
    MediaLibraryCollection,
    JetDialogModal,
    JetSecondaryButton,
    Datepicker,
    VueTagsInput,
    BadgeCheckIcon,
    ImageEditor,
  },
  setup(props) {
    const tab = ref(1);
    const currentTab = (tabNumber) => (tab.value = tabNumber);
    const debounce = ref(null);
    // Связанные материалы
    const rubricsTopMenuTagText = ref('');
    const rubricsTopMenuAutocompleteItems = ref([]);
    let rubricsTopMenu = [];
    //
    // Материалы для главной
    const materialsHomePageTagText = ref('');
    const materialsHomePageAutocompleteItems = ref([]);
    let materialsHomePage = [];
    //
    // Материалы для главной драфт 1
    const materialsHomePageTagTextDraft1 = ref('');
    const materialsHomePageAutocompleteItemsDraft1 = ref([]);
    let materialsHomePageDraft1 = [];
    //
    console.log('props.list', props.list);
    //
    //
    const settings = reactive({
      menu: {
        // 'header__rubrics': null
      },
    });

    const images = ref({
      homepage__fs_content_1280: null,
      homepage__fs_content_1024: null,
      homepage__fs_content_768: null,
      homepage__fs_content_375: null,
      homepage__first_banner: null,
    });

    const saveSettingsLoading = ref(false);
    props.list.forEach((item) => {
      console.log(item);
      if (typeof settings[item.type] === 'undefined') {
        settings[item.type] = {};
      }
      settings[item.type][item.name] = {
        name: item.name,
        title: item.title,
        data: item.data,
      };

      if (item.media.length > 0) {
        images.value[item.name] = item.media;
      }

      switch (item.name) {
        case 'header__rubrics':
          console.log('item.data.value', item.data.value);
          console.log('item.tags', item.tags);
          item.data.value.forEach((tagId) => {
            tagId = parseInt(tagId);
            rubricsTopMenu.push({
              id: tagId,
              text: item.tags[tagId],
              classes: 'custom-class',
            });
          });
          settings[item.type][item.name].data.value = rubricsTopMenu;
          break;
        case 'homepage__works':
          console.log('homepage__works -- item.data.value', item.data.value);
          console.log('homepage__works -- item.materials', item.materials);
          item.data.value.forEach((materialId) => {
            materialId = parseInt(materialId);
            materialsHomePage.push({
              id: materialId,
              text: item.materials[materialId],
              classes: 'custom-class',
            });
          });
          settings[item.type][item.name].data.value = materialsHomePage;
          break;
        case 'homepage__works_draft_1':
          item.data.value.forEach((materialId) => {
            materialId = parseInt(materialId);
            materialsHomePageDraft1.push({
              id: materialId,
              text: item.materials[materialId],
              classes: 'custom-class',
            });
          });
          settings[item.type][item.name].data.value = materialsHomePageDraft1;
      }
    });

    //
    console.log('settings', settings);
    //
    //
    function saveSettings(callback) {
      saveSettingsLoading.value = true;

      settings.homepage['homepage__fs_content_1280'].data.value =
        images.value['homepage__fs_content_1280'];
      settings.homepage['homepage__fs_content_1024'].data.value =
        images.value['homepage__fs_content_1024'];
      settings.homepage['homepage__fs_content_768'].data.value =
        images.value['homepage__fs_content_768'];
      settings.homepage['homepage__fs_content_375'].data.value =
        images.value['homepage__fs_content_375'];
      settings.homepage['homepage__first_banner'].data.value =
        images.value['homepage__first_banner'];

      axios.post('/admin/settings-save', settings).then((res) => {
        console.log(res);
        saveSettingsLoading.value = false;
        if (typeof callback === 'function') {
          callback();
        }
      });
    }
    //
    const toggleHomeMaterialLoading = ref(false);
    function toggleHomeMaterial() {
      toggleHomeMaterialLoading.value = true;
      saveSettings(function () {
        axios.post('/admin/settings-toggle-home-material').then((res) => {
          console.log(res);
          toggleHomeMaterialLoading.value = false;
        });
      });
    }
    //
    onUpdated(() => { });

    const onChangeUniversal = function (name, img) {
      images.value[name] = img;
    };

    const onChangeUniversalNEW = function (name, img) {
      images.value[name] = img.value;
    };

    //
    //
    return {
      images,
      onChangeUniversal,
      onChangeUniversalNEW,

      //Content
      // form,
      settings,
      saveSettingsLoading,
      saveSettings,
      //System
      tab,
      currentTab,
      //Gallery
      debounce,
      rubricsTopMenuAutocompleteItems,
      rubricsTopMenuTagText,
      // Homepage
      materialsHomePageAutocompleteItems,
      materialsHomePageTagText,
      //
      materialsHomePageAutocompleteItemsDraft1,
      materialsHomePageTagTextDraft1,
      toggleHomeMaterialLoading,
      //
    };
  },
  data() {
    return {
      validationErrors: this.$page.props.errors,
    };
  },
  props: {
    list: Object,
    prefixRoute: String,
    title: String,
    titleSection: String,
    descSection: String,
  },
  methods: {
    submit() {
      this.form.put(route(`${this.prefixRoute}.update`, this.model.id));
    },
    // Rubrics input
    rubricsTopMenuUpdate(newTags) {
      this.rubricsTopMenuAutocompleteItems = [];
      this.settings['menu']['header__rubrics'].data.value = newTags;
    },
    //
    materialsHomePageUpdate(newTags) {
      this.materialsHomePageAutocompleteItems = [];
      this.settings['homepage']['homepage__works'].data.value = newTags;
    },
    //
    materialsHomePageUpdateDraft1(newTags) {
      this.materialsHomePageAutocompleteItemsDraft1 = [];
      this.settings['homepage']['homepage__works_draft_1'].data.value = newTags;
    },
    //
    //
    initRubricsTopMenuItems() {
      if (this.rubricsTopMenuTagText.length < 2) return;
      const url = `/admin/tags-search?term=${this.rubricsTopMenuTagText}`;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        axios
          .get(url)
          .then((response) => {
            console.log(response.data);
            this.rubricsTopMenuAutocompleteItems = response.data.results.map(
              (a) => {
                return { id: a.id, text: a.title };
              }
            );
          })
          .catch(() => console.warn('Oh. Something went wrong'));
      }, 600);
    },
    //
    initMaterialsHomePageItems() {
      if (this.materialsHomePageTagText.length < 2) return;
      const url = `/admin/works-search?term=${this.materialsHomePageTagText}`;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        axios
          .get(url)
          .then((response) => {
            console.log(response.data);
            this.materialsHomePageAutocompleteItems = response.data.results.map(
              (a) => {
                return { id: a.id, text: a.title };
              }
            );
          })
          .catch(() => console.warn('Oh. Something went wrong'));
      }, 600);
    },
    //
    initMaterialsHomePageItemsDraft1() {
      if (this.materialsHomePageTagTextDraft1.length < 2) return;
      const url = `/admin/works-search?term=${this.materialsHomePageTagTextDraft1}`;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        axios
          .get(url)
          .then((response) => {
            console.log(response.data);
            this.materialsHomePageAutocompleteItemsDraft1 =
              response.data.results.map((a) => {
                return { id: a.id, text: a.title };
              });
          })
          .catch(() => console.warn('Oh. Something went wrong'));
      }, 600);
    },
  },
  watch: {
    rubricsTopMenuTagText: 'initRubricsTopMenuItems',
    materialsHomePageTagText: 'initMaterialsHomePageItems',
    materialsHomePageTagTextDraft1: 'initMaterialsHomePageItemsDraft1',
  },
  computed: {},
});
</script>

<style>
@import 'media-library-pro-styles';

.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}

.thumb {
  width: 4rem !important;
  height: 4rem !important;
  object-fit: cover !important;
}

.media-library-thumb {
  width: 100px !important;
  height: 100px !important;
  margin: 4rem;
}
</style>
