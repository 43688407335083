<template>
  <Head title="Register" />

  <jet-authentication-card>
    <template #logo>
      <jet-authentication-card-logo />
    </template>

    <div class="sm:max-w-md w-full">
      <a
        href="/auth-google-redirect"
        class="block active:bg-slate-900 bg-sky-600 border border-transparent cursor-pointer font-light disabled:opacity-25 focus:border-slate-900 focus:outline-none focus:ring focus:ring-slate-300 hover:bg-slate-700 items-center mb-4 px-4 py-2 py-4 rounded-md text-center text-white text-xs tracking-widest transition w-full"
      >
        Регистрация c Google
      </a>
      <!--			<div @click="authAppleID"-->
      <!--					 class="block active:bg-slate-900 bg-slate-900 border border-transparent cursor-pointer font-light disabled:opacity-25 focus:border-slate-900 focus:outline-none focus:ring focus:ring-slate-300  hover:bg-slate-700 items-center px-4 py-2 py-4 rounded-md text-center text-white text-xs tracking-widest transition  w-full">-->
      <!--				Регистрация c Apple ID-->
      <!--			</div>-->

      <div class="mt-6 text-center">или</div>
    </div>

    <jet-validation-errors class="mb-4" />

    <form @submit.prevent="submit">
      <!--			<div>-->
      <!--				<jet-label for="name" value="Имя"/>-->
      <!--				<jet-input id="name" type="text" class="mt-1 block w-full" v-model="form.name" required autofocus-->
      <!--									 autocomplete="name"/>-->
      <!--			</div>-->

      <div class="mt-4">
        <jet-label for="email" value="Email" />
        <jet-input
          id="email"
          type="email"
          class="mt-1 block w-full"
          v-model="form.email"
          required
        />
      </div>

      <!--			<div class="mt-4">-->
      <!--				<jet-label for="password" value="Password"/>-->
      <!--				<jet-input id="password" type="password" class="mt-1 block w-full" v-model="form.password" required-->
      <!--									 autocomplete="new-password"/>-->
      <!--			</div>-->

      <!--			<div class="mt-4">-->
      <!--				<jet-label for="password_confirmation" value="Confirm Password"/>-->
      <!--				<jet-input id="password_confirmation" type="password" class="mt-1 block w-full"-->
      <!--									 v-model="form.password_confirmation" required autocomplete="new-password"/>-->
      <!--			</div>-->

      <!--			<div class="mt-4" v-if="$page.props.jetstream.hasTermsAndPrivacyPolicyFeature">-->
      <!--				<jet-label for="terms">-->
      <!--					<div class="flex items-center">-->
      <!--						<jet-checkbox name="terms" id="terms" v-model:checked="form.terms"/>-->

      <!--						<div class="ml-2">-->
      <!--							Я согласен <a target="_blank" :href="route('terms.show')"-->
      <!--														class="underline text-sm text-slate-600 hover:text-slate-900">с правилами оказания услуг.</a>-->
      <!--							и <a-->
      <!--										target="_blank" :href="route('policy.show')"-->
      <!--										class="underline text-sm text-slate-600 hover:text-slate-900">политикой конфиденциальности</a>-->
      <!--						</div>-->
      <!--					</div>-->
      <!--				</jet-label>-->
      <!--			</div>-->

      <div class="flex items-center justify-end mt-4">
        <Link
          :href="route('login')"
          class="underline text-sm text-slate-600 hover:text-slate-900"
        >
          Уже зарегистрированы?
        </Link>

        <jet-button
          class="ml-4"
          :class="{ 'opacity-25': form.processing }"
          :disabled="form.processing"
        >
          Регистрация
        </jet-button>
      </div>
    </form>
  </jet-authentication-card>
</template>

<script>
import { defineComponent } from 'vue';
import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue';
import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetValidationErrors from '@/Jetstream/ValidationErrors.vue';
import { Head, Link } from '@inertiajs/inertia-vue3';

export default defineComponent({
  components: {
    Head,
    JetAuthenticationCard,
    JetAuthenticationCardLogo,
    JetButton,
    JetInput,
    JetCheckbox,
    JetLabel,
    JetValidationErrors,
    Link,
  },
  data() {
    return {
      form: this.$inertia.form({
        //name: '',
        email: '',
        //password: '',
        //password_confirmation: '',
        terms: false,
      }),
    };
  },
  methods: {
    submit() {
      this.form.post(this.route('register.post'), {
        onFinish: () => {
          // this.form.reset('password', 'password_confirmation')
        },
      });
    },
    authAppleID() {
      alert('Скоро! Но не сейчас.');
    },
  },
});
</script>
