<script setup>
import { ref, watch, onMounted, computed } from 'vue';
import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);

const props = defineProps({
    modelClass: { type: String, required: true },
    modelId: { type: Number, required: false },
    limit: { type: Number, default: 50 }
});

const logs = ref([]);
const loading = ref(false);
const currentPage = ref(1);
const lastPage = ref(1);

const fetchLogs = async () => {
    loading.value = true;

    const url = props.modelId
        ? `/admin/logs/${props.modelClass}/${props.modelId}?limit=${props.limit}&page=${currentPage.value}`
        : `/admin/logs/${props.modelClass}?limit=${props.limit}&page=${currentPage.value}`;

    try {
        const response = await axios.get(url);
        logs.value = response.data.data;
        currentPage.value = response.data.current_page;
        lastPage.value = response.data.last_page;
    } catch (error) {
        console.error('Error fetching logs:', error);
    } finally {
        loading.value = false;
    }
};

watch([() => props.modelClass, () => props.modelId, currentPage], fetchLogs);
onMounted(fetchLogs);

const formatDate = (date) => {
    const day = dayjs(date);
    if (day.isSame(dayjs(), 'day')) return 'Today';
    if (day.isSame(dayjs().subtract(1, 'day'), 'day')) return 'Yesterday';
    return day.format('MMMM D, YYYY');
};

const groupedLogs = computed(() => {
    return logs.value.reduce((acc, log) => {
        const date = formatDate(log.created_at);
        if (!acc[date]) acc[date] = [];
        acc[date].push(log);
        return acc;
    }, {});
});
</script>

<template>
    <div class="w-full text-[14px]">
        <div class="overflow-hidden border-t border-slate-100">
            <table class="w-full text-left">
                <thead class="sr-only">
                    <tr>
                        <th>Date</th>
                        <th>User</th>
                        <th>Action</th>
                        <th>Changes</th>
                    </tr>
                </thead>

                <tbody>
                    <template v-for="(logsGroup, date) in groupedLogs" :key="date">
                        <tr class="text-slate-900">
                            <th scope="colgroup" colspan="4" class="relative isolate py-2 font-semibold bg-slate-50">
                                {{ date }}
                            </th>
                        </tr>

                        <tr v-for="log in logsGroup" :key="log.id">
                            <td class="hidden md:table-cell py-5 pr-6">
                                {{ formatDate(log.created_at) }}
                            </td>

                            <td class="py-5 pr-6">
                                <div class="font-medium text-slate-900">
                                    <template v-if="log.causer">
                                        {{ log.causer.name || `User #${log.causer_id}` }}
                                    </template>
                                    <template v-else>
                                        System
                                    </template>
                                </div>
                                <div class="md:hidden  text-slate-500">
                                    {{ formatDate(log.created_at) }} <br> {{ log.description }}
                                </div>
                            </td>

                            <td class="hidden md:table-cell py-5 pr-6">
                                <div class="text-slate-900 font-semibold">
                                    {{ log.description }}
                                </div>
                            </td>

                            <td class="py-5">
                                <div v-if="log.properties && log.properties.old" class="text-slate-700">
                                    <ul>
                                        <li
                                            v-for="(value, key) in log.properties.old"
                                            :key="key"
                                            class="flex flex-col md:flex-row gap-[5px]"
                                        >
                                            <span class="text-slate-500">{{ key }}:</span>
                                            <span class="text-red-500">{{ value }}</span>
                                            →
                                            <span class="text-green-500" v-if="log.properties.attributes[key]">{{ log.properties.attributes[key] }}</span>
                                        </li>
                                    </ul>
                                </div>


                                <div v-else-if="log.properties && log.properties.file_name">
                                    <div class="text-slate-700 flex items-center gap-[10px]">
                                        
                                        <div>
                                            (ID: {{ log.properties.media_id }})
                                        </div>
                                    </div>
                                </div>

                                <span v-else class="text-slate-400">
                                    No changes
                                </span>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>

            <div v-if="lastPage > 1" class="mt-4 flex justify-between">
                <button
                    @click="currentPage--"
                    :disabled="currentPage === 1"
                    class="px-4 py-2 bg-slate-300 text-slate-700 rounded hover:bg-slate-400"
                >
                    Prev
                </button>

                <span class="text-slate-600">
                    Page {{ currentPage }} of {{ lastPage }}
                </span>

                <button
                    @click="currentPage++"
                    :disabled="currentPage === lastPage"
                    class="px-4 py-2 bg-slate-300 text-slate-700 rounded hover:bg-slate-400"
                >
                    Next
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped>
button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
</style>
