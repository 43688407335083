<template>
  <app-layout :title="``">
    <template #header>
      <div class="flex w-full justify-between items-center">
        <h2 class="flex flex-col md:flex-row md:items-center md:text-[24px] text-[14px] leading-none" v-html="title"></h2>
        <div class="flex gap-x-[5px] md:gap-x-[40px] justify-between">
          <app-useful-links :items="usefulLinks" />
          <a :href="route(`${prefixRoute}.create`)">
            <jet-button>Create</jet-button>
          </a>
        </div>
      </div>
    </template>

    <div class="mx-auto py-4">
      <div class="flow-root">
        <div class="overflow-x-auto">
          <div class="inline-block min-w-full align-middle">
            <table class="min-w-full divide-y divide-slate-300">
              <thead>
                <tr>
                  <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0">
                    <span class="sr-only">Edit</span>
                  </th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900">
                    Year
                  </th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900">
                    Value
                  </th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900">
                    Work
                  </th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900">
                    Name
                  </th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-slate-900">
                    Nomination
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-slate-200 bg-white">
                <tr v-for="item in modelList.data" :key="item.id">
                  <td class="whitespace-nowrap py-2 px-0">
                    <EditButton :href="route(`${prefixRoute}.show`, item.id)" />
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-500">
                    {{ item.year }}
                    <div class="inline-flex items-center">
                      <span v-if="item.published">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 text-green-600" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                      </span>
                      <span v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 text-slate-400" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                        </svg>
                      </span>
                    </div>
                  </td>

                  <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-500">
                    <div class="flex items-center">
                      <img v-if="item.cover" :src="item.cover" class="w-4 h-4 inline-block mr-2" alt="cover image" />
                      <span>{{ item.value }}</span>
                    </div>
                  </td>

                  <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-900">
                    <div class="max-w-[200px] truncate overflow-hidden">
                      <a :href="`/admin/awards?page=1&filter%5Bglobal%5D=${item.case_name}`">{{ item.case_name }}</a>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-500">
                    <div class="max-w-[200px] truncate overflow-hidden">
                      {{ item.name }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm text-slate-500">
                    <div class="max-w-[200px] truncate overflow-hidden">
                      {{ item.nomination }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isWork">
      <draggable :list="modelList.data" group="items" class="flex flex-col gap-[10px]" handle=".handle" itemKey="id">
        <template #item="{ element }">
          <div class="bg-slate-100 px-2 py-1 cursor-move hover:bg-slate-50">
            <div class="handle">{{ element.name }}</div>
          </div>
        </template>
      </draggable>

      <div class="flex items-center justify-start pt-8">
        <jet-button @click="orderSave">Save</jet-button>
        <jet-action-message :on="orderSaved" class="ml-3">Saved.</jet-action-message>
      </div>
    </div>

    <template v-for="year in worksList">
      <div v-if="year.length > 1" class="mt-5 border px-5 py-3 rounded">
        <div class="text-lg mb-2">{{ year[0].year }}</div>
        <draggable :list="year" group="items" class="flex flex-col gap-[10px]" handle=".handle" itemKey="id">
          <template #item="{ element }">
            <div class="bg-slate-100 px-2 py-1 cursor-move hover:bg-slate-50">
              <div class="handle">{{ element.name }}</div>
            </div>
          </template>
        </draggable>
      </div>
    </template>

    <div class="flex items-center justify-start pt-8">
      <jet-button @click="orderWorksSave">Save</jet-button>
      <jet-action-message :on="orderWorksSaved" class="ml-3">Saved.</jet-action-message>
    </div>

    <div class="w-full mt-[10px] md:mt-[20px]">
      <ActivityLogGroup modelClass="award" />
    </div>

  </app-layout>
</template>

<script setup>
import { ref, computed } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import AppUsefulLinks from '@/Components/usefulLinks.vue';
import EditButton from '@/Components/Tables/EditButton.vue';
import JetActionMessage from '@/Jetstream/ActionMessage';
import draggable from 'vuedraggable';
import ActivityLogGroup from '@/Components/ActivityLogGroup.vue';

const props = defineProps({
  modelList: Object,
  prefixRoute: String,
  title: String,
  usefulLinks: Object,
  works: Array,
});

const worksList = ref(props.works);
const orderSaved = ref(false);
const orderWorksSaved = ref(false);

const isWork = computed(() => {
  const caseNameArray = new Set(
    props.modelList.data.map((item) => item.case_name)
  );
  return caseNameArray.size === 1 && props.modelList.data.length > 1;
});

const orderSave = () => {
  const items = props.modelList.data.map((item) => item.id);
  axios
    .post(route('admin::awards.sort-item'), { items })
    .then(() => {
      orderSaved.value = true;
      setTimeout(() => (orderSaved.value = false), 3000);
    })
    .catch(console.error);
};

const orderWorksSave = () => {
  axios
    .post(route('admin::awards.sort-work-item'), { items: worksList.value })
    .then(() => {
      orderWorksSaved.value = true;
      setTimeout(() => (orderWorksSaved.value = false), 3000);
    })
    .catch(console.error);
};
</script>
