<template>
  <textarea
    v-if="!plus"
    class="px-4 py-2 outline-none w-full border border-slate-300 focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50 rounded-md shadow-sm"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    ref="input"
  />
</template>

<script>
import {
  defineComponent,
  ref,
  onMounted,
  onUpdated,
  reactive,
  toRefs,
} from 'vue';
import JetDialogModalLaraberg from './DialogModalLaraberg';
import JetButton from './Button.vue';
import JetInput from './Input.vue';
import JetInputError from './InputError.vue';
import JetSecondaryButton from './SecondaryButton.vue';

export default defineComponent({
  props: {
    modelValue: {
      type: String,
    },
    plus: {
      type: Boolean,
      default() {
        return false;
      },
    },
    larabergUrl: {
      type: String,
      require: false,
    },
  },
  setup(props, context) {
    const isOpenLaraberg = ref(false);
    return {
      isOpenLaraberg,
    };
  },
  emits: ['update:modelValue'],
  components: {
    JetButton,
    JetDialogModalLaraberg,
    JetInput,
    JetInputError,
    JetSecondaryButton,
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    truncate(source, size) {
      return source.length > size ? source.slice(0, size - 1) + '…' : source;
    },
    closeModal() {
      this.isOpenLaraberg = false;
    },
    openLaraberg() {
      let self = this;
      self.isOpenLaraberg = true;
    },
  },
  computed: {
    formattedHTML() {
      let self = this;
      let html = self.modelValue;
      let tmp = document.createElement('div');
      tmp.innerHTML = html;
      let text = tmp.textContent || tmp.innerText || '';
      text = text.replace(/ +/g, ' ').trim();
      text = text.replace(new RegExp('\\r?\\n', 'g'), '');
      text = self.truncate(text, 150);
      return text;
    },
  },
});
</script>
